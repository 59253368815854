function LandingGraphicRight({ className }) {
    return ( 
        <svg className={className} width="615" height="360" viewBox="0 0 615 360" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M614.2 359.4H449.3V307.9C487.7 271.3 531.5 296.5 534.4 323.7C578.1 295.3 619.2 318 614.2 359.4Z" fill="#404EED"/>
            <path d="M449.301 223.5H13.7007V359.4H449.301V223.5Z" fill="#5865F2"/>
            <path d="M13.7007 265.5L449.301 230.4V223.5H13.7007V265.5Z" fill="#4B59F2"/>
            <path d="M449.301 223.5H446.101V359.4H449.301V223.5Z" fill="#3543DE"/>
            <path d="M410.001 223.5H406.801V359.4H410.001V223.5Z" fill="#3543DE"/>
            <path d="M370.701 223.5H367.501V359.4H370.701V223.5Z" fill="#3543DE"/>
            <path d="M331.401 223.5H328.201V359.4H331.401V223.5Z" fill="#3543DE"/>
            <path d="M292.001 223.5H288.801V359.4H292.001V223.5Z" fill="#3543DE"/>
            <path d="M252.701 223.5H249.501V359.4H252.701V223.5Z" fill="#3543DE"/>
            <path d="M213.401 223.5H210.201V359.4H213.401V223.5Z" fill="#3543DE"/>
            <path d="M174.101 223.5H170.901V359.4H174.101V223.5Z" fill="#3543DE"/>
            <path d="M134.801 223.5H131.601V359.4H134.801V223.5Z" fill="#3543DE"/>
            <path d="M95.5007 223.5H92.3008V359.4H95.5007V223.5Z" fill="#3543DE"/>
            <path d="M56.2007 223.5H53.0007V359.4H56.2007V223.5Z" fill="#3543DE"/>
            <path d="M16.9008 223.5H13.7007V359.4H16.9008V223.5Z" fill="#3543DE"/>
            <path d="M131.901 97.8C127.801 84.2 125.601 77.7 133.001 73.7C140.401 69.7 162.901 72.4 173.401 74.9C183.901 77.4 185.401 80 178.501 90.3L131.901 97.8Z" fill="#FFF4B3"/>
            <path d="M127.101 220.1C125.501 179.7 126.501 146.1 127.801 128.2C121.101 123.8 118.001 120.4 116.701 118.5C116.431 118.134 116.293 117.687 116.312 117.232C116.33 116.778 116.502 116.343 116.801 116C127.401 102.7 136.501 91.1 146.001 85.7C155.501 80.3 168.501 78.9 189.201 99.5C189.399 99.7637 189.497 100.089 189.479 100.418C189.461 100.748 189.327 101.06 189.101 101.3C187.457 102.932 185.721 104.468 183.901 105.9C188.101 113.4 194.201 124.8 194.201 124.8H199.001C202.077 124.8 205.028 126.022 207.203 128.198C209.379 130.373 210.601 133.323 210.601 136.4C210.701 145.7 208.501 156.9 204.201 171.3C223.401 186.9 218.701 220 218.701 220L127.101 220.1Z" fill="#29CC7A"/>
            <path d="M204.201 171.3C204.801 169.2 205.401 167.3 205.901 165.3L190.801 173.7C190.801 173.7 166.901 157.6 126.801 147.6C126.101 166.2 126.001 191.4 127.101 220.1H218.701C218.701 220.1 223.401 186.9 204.201 171.3Z" fill="#8CD9FF"/>
            <path d="M208.001 175C206.835 173.667 205.564 172.43 204.201 171.3C204.801 169.2 205.401 167.3 205.901 165.3L190.801 173.7C190.801 173.7 166.901 157.6 126.801 147.6C126.701 149.9 126.601 152.4 126.601 154.9C157.301 167.6 173.801 189.4 175.701 220.1H198.401C202.401 202.5 205.601 187.1 208.001 175Z" fill="#BAE8FF"/>
            <path d="M138.901 135.9H139.301C139.501 135.7 139.701 135.6 139.801 135.3L148.501 107.2C148.559 107.082 148.592 106.953 148.595 106.821C148.598 106.69 148.573 106.559 148.52 106.439C148.468 106.318 148.389 106.21 148.291 106.123C148.192 106.036 148.076 105.972 147.949 105.935C147.823 105.898 147.69 105.889 147.56 105.908C147.43 105.928 147.306 105.976 147.196 106.049C147.087 106.122 146.995 106.218 146.926 106.331C146.858 106.443 146.815 106.569 146.801 106.7L138.401 133.9C134.772 132.352 131.291 130.477 128.001 128.3H127.801C127.801 128.9 127.701 129.6 127.701 130.2C131.156 132.423 134.804 134.331 138.601 135.9H138.901Z" fill="black"/>
            <path d="M176.101 90.7L140.301 93.3C180.301 100.9 182.001 102 176.101 90.7Z" fill="black"/>
            <path d="M195.901 195.5L192.101 160C192.026 159.373 191.716 158.797 191.233 158.389C190.751 157.981 190.132 157.77 189.501 157.8H185.701L184.001 154.5C183.475 153.591 182.642 152.9 181.652 152.552C180.662 152.203 179.579 152.22 178.601 152.6C177.592 153.029 176.774 153.811 176.301 154.8C168.801 170.9 165.101 197.4 164.401 202.4C164.385 202.634 164.444 202.867 164.57 203.065C164.696 203.263 164.882 203.415 165.101 203.5L186.701 210.4C186.821 210.463 186.957 210.489 187.092 210.476C187.227 210.463 187.355 210.41 187.461 210.326C187.567 210.241 187.646 210.127 187.689 209.998C187.731 209.869 187.735 209.731 187.701 209.6L186.901 199.5L194.901 197C195.217 196.908 195.491 196.707 195.674 196.432C195.857 196.158 195.937 195.828 195.901 195.5Z" fill="#EB459F"/>
            <path d="M177.401 167.2L174.101 201.7" stroke="#D11583" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M167.154 116.711C168.536 116.153 168.785 113.541 167.709 110.878C166.633 108.216 164.641 106.51 163.258 107.068C161.875 107.627 161.627 110.238 162.703 112.901C163.778 115.564 165.771 117.27 167.154 116.711Z" fill="black"/>
            <path d="M177.463 122.24C179.246 118.872 179.03 115.262 176.98 114.177C174.93 113.091 171.823 114.942 170.039 118.309C168.256 121.677 168.473 125.287 170.522 126.373C172.572 127.458 175.68 125.608 177.463 122.24Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M192.188 122.26C193.971 118.893 193.755 115.283 191.705 114.197C189.655 113.112 186.548 114.962 184.765 118.33C182.982 121.698 183.198 125.308 185.248 126.393C187.298 127.478 190.405 125.628 192.188 122.26Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M182.074 116.228C182.996 115.856 182.905 113.479 181.87 110.918C180.836 108.358 179.25 106.584 178.328 106.957C177.407 107.329 177.498 109.706 178.532 112.267C179.567 114.827 181.153 116.601 182.074 116.228Z" fill="black"/>
            <path d="M191.601 142.4C183.88 142.425 176.388 139.775 170.401 134.9C164.201 129.8 166.701 129.2 170.601 129.1C174.501 129 193.201 129.7 198.601 130.5C204.001 131.3 205.001 142 191.601 142.4Z" fill="#912869"/>
            <path d="M191.601 142.4C183.88 142.425 176.388 139.775 170.401 134.9C164.201 129.8 166.701 129.2 170.601 129.1C174.501 129 193.201 129.7 198.601 130.5C204.001 131.3 205.001 142 191.601 142.4Z" fill="#611B46"/>
            <path d="M191.201 153.6L190.801 149.9C193.626 149.675 196.379 148.893 198.901 147.6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M195.301 141.9C189.901 136.7 182.601 132.8 169.101 133.7C180.701 144.4 192.801 142.6 195.301 141.9Z" fill="#EB459F"/>
            <path d="M178.101 118.7H184.201" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M168.901 119.9L143.101 109.4C142.56 109.19 141.976 109.115 141.4 109.179C140.823 109.244 140.271 109.447 139.789 109.771C139.308 110.095 138.912 110.531 138.636 111.041C138.359 111.551 138.21 112.12 138.201 112.7V112.7C138.189 113.383 138.383 114.053 138.758 114.624C139.132 115.194 139.67 115.639 140.301 115.9L142.001 116.7" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M163.501 106.8C165.53 106.852 167.556 106.582 169.501 106" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M178.501 106.8C179.78 106.833 181.049 106.559 182.201 106" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M135.301 94.2999C171.001 93.7999 183.101 89.7999 193.301 86.0999C203.501 82.3999 202.501 79.4999 194.701 79.7999C186.901 80.0999 160.201 90.2999 135.301 94.2999Z" fill="#FFF4B3"/>
            <path d="M126.501 158.1L126.301 167.9L131.201 166C131.201 166 157.301 155.1 163.501 153.6C169.701 152.1 175.601 155 184.501 158.4C193.401 161.8 191.101 164.9 188.301 167.9C185.501 170.9 185.601 170.7 170.901 170.7C142.801 191 134.201 202.9 122.701 202.9C115.801 202.9 109.901 199.8 105.501 186.2C101.101 172.6 109.301 166.4 126.501 158.1Z" fill="#29CC7A"/>
            <path d="M224.401 169.3L209.401 151.2L183.801 176.7L201.901 191.8C201.701 193.5 201.901 194.8 202.801 195.7C205.501 198.4 213.401 194.9 220.501 187.9C227.601 180.9 231.001 172.9 228.301 170.2C227.401 169.3 226.101 169.1 224.401 169.3Z" fill="white"/>
            <path d="M199.965 167.378C206.995 160.348 211.174 153.13 209.299 151.256C207.425 149.381 200.207 153.56 193.177 160.59C186.148 167.619 181.969 174.837 183.843 176.712C185.718 178.586 192.936 174.407 199.965 167.378Z" fill="white"/>
            <path d="M198.227 165.664C204.593 159.298 208.866 153.251 207.773 152.158C206.68 151.064 200.633 155.338 194.267 161.704C187.902 168.069 183.628 174.116 184.721 175.21C185.815 176.303 191.862 172.029 198.227 165.664Z" fill="#BAE8FF"/>
            <path d="M223.001 161.7C227.301 162.3 229.001 166.2 227.301 171.4C227.108 171.954 226.776 172.448 226.336 172.835C225.896 173.223 225.363 173.49 224.79 173.61C224.216 173.731 223.621 173.702 223.062 173.525C222.504 173.347 222 173.029 221.601 172.6C220.612 171.551 220.013 170.194 219.903 168.756C219.794 167.318 220.182 165.887 221.001 164.7L223.001 161.7Z" fill="#29CC7A"/>
            <path d="M215.501 158.7C215.301 162.4 213.101 166.1 211.501 168.5C211.069 169.173 210.874 169.972 210.946 170.768C211.019 171.565 211.355 172.315 211.901 172.9L212.701 173.6C212.961 173.866 213.273 174.077 213.616 174.222C213.96 174.366 214.328 174.44 214.701 174.44C215.073 174.44 215.442 174.366 215.785 174.222C216.129 174.077 216.44 173.866 216.701 173.6C219.714 170.85 221.838 167.264 222.801 163.3C224.001 157.9 220.101 154.1 213.301 155.9L215.501 158.7Z" fill="#29CC7A"/>
            <path d="M135.301 94.2999L147.501 93.2999L176.801 87.4999L169.501 80.2999H134.601L135.301 94.2999Z" fill="#FFF4B3"/>
            <path d="M194.401 131.2L187.401 130.8C188.201 135 190.201 137.2 191.901 136.5C193.601 135.8 194.201 132.1 194.401 131.2Z" fill="white"/>
            <path d="M172.801 130.3C173.701 135.1 175.501 137.6 177.201 136.8C178.901 136 179.301 132 179.601 130.4L172.801 130.3Z" fill="white"/>
            <path d="M465.401 223.5H4.30078" stroke="#2835BD" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M184.401 174.7C184.476 174.906 184.616 175.082 184.801 175.2C184.901 175.2 184.901 175.2 184.901 175.3C184.901 175.4 192.101 171.8 198.301 165.6V165.4L194.401 161.6L191.201 158.3L187.001 158.5L178.801 155.4L178.501 156.1C182.001 157.3 182.701 169.9 184.401 174.7Z" fill="#EB459F"/>
            <path d="M185.701 157.9C187.389 161.316 188.891 164.821 190.201 168.4C190.27 168.561 190.38 168.702 190.52 168.807C190.661 168.912 190.826 168.979 191.001 169H191.301C191.511 168.912 191.685 168.756 191.794 168.556C191.904 168.355 191.941 168.124 191.901 167.9C191.801 167.6 189.601 161.8 187.601 157.9H185.701Z" fill="#D11583"/>
            <path d="M186.901 199.7H186.701L179.001 202C178.859 202.043 178.73 202.12 178.626 202.225C178.521 202.329 178.444 202.458 178.401 202.6C178.372 202.763 178.385 202.931 178.437 203.088C178.49 203.245 178.58 203.387 178.701 203.5L185.501 209.4C185.542 209.496 185.613 209.577 185.703 209.63C185.792 209.684 185.897 209.709 186.001 209.7C186.132 209.704 186.263 209.679 186.384 209.628C186.505 209.576 186.613 209.498 186.701 209.4C186.782 209.309 186.845 209.203 186.884 209.088C186.923 208.972 186.939 208.85 186.93 208.729C186.92 208.607 186.886 208.489 186.83 208.38C186.773 208.272 186.695 208.177 186.601 208.1L181.001 203.2L187.001 201.4L186.901 199.7Z" fill="#D11583"/>
            <path d="M126.001 167.6L111.901 174.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M180.901 82.7999C165.488 86.9292 149.866 90.2339 134.101 92.6999" stroke="#FFC619" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M193.401 178.6L204.201 188.5C204.54 188.798 204.967 188.978 205.418 189.014C205.868 189.05 206.318 188.94 206.701 188.7C206.943 188.528 207.145 188.303 207.29 188.044C207.435 187.784 207.52 187.495 207.539 187.198C207.558 186.901 207.511 186.603 207.4 186.327C207.29 186.05 207.119 185.802 206.901 185.6L196.401 175.5C196.08 175.209 195.678 175.023 195.248 174.97C194.819 174.916 194.383 174.996 194.001 175.2L193.701 175.4C193.433 175.562 193.208 175.784 193.042 176.049C192.876 176.313 192.774 176.613 192.745 176.924C192.716 177.235 192.76 177.549 192.874 177.84C192.988 178.131 193.168 178.391 193.401 178.6Z" fill="#BAE8FF"/>
            <path d="M220.501 170.5C222.325 168.079 223.465 165.212 223.801 162.2" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M309.19 32.2318C310.101 27.9085 307.247 23.6467 302.816 22.7128C298.384 21.779 294.053 24.5267 293.142 28.85C292.231 33.1734 295.085 37.4351 299.516 38.369C303.948 39.3028 308.279 36.5551 309.19 32.2318Z" fill="#2A39D4"/>
            <path d="M362.699 106.5C369.999 106.9 378.799 109.9 389.799 122.1C395.999 115.9 398.999 117.6 407.299 124.7C415.599 131.8 433.399 145.3 437.799 149.8C442.199 154.3 445.499 164 433.499 171.3C421.499 178.6 400.199 165.2 388.999 157.4C377.799 149.6 362.699 140.5 362.699 140.5V106.5Z" fill="#2331B5"/>
            <path d="M403.999 121.9C406.907 126.69 409.352 131.746 411.299 137C413.599 144.2 410.699 148.4 404.799 153.3C398.899 158.2 396.499 157.1 393.399 154.6C393.212 154.493 392.992 154.457 392.78 154.499C392.569 154.54 392.379 154.656 392.246 154.826C392.112 154.996 392.044 155.207 392.054 155.423C392.064 155.638 392.151 155.843 392.299 156C393.884 157.391 395.893 158.202 397.999 158.3C400.499 158.3 402.999 157.1 405.999 154.6C411.099 150.3 415.899 145.4 412.899 136.4C411.787 133.162 410.416 130.018 408.799 127C408.17 125.789 407.322 124.704 406.299 123.8L403.999 121.9Z" fill="#3442D9"/>
            <path d="M388.899 151.8C394.299 157 407.199 166.2 407.199 166.2" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M335.881 105.224C361.748 97.8552 377.333 72.9792 370.691 49.6615C364.049 26.3437 337.694 13.4144 311.827 20.7828C285.96 28.1513 270.375 53.0274 277.017 76.3451C283.66 99.6628 310.014 112.592 335.881 105.224Z" fill="#FF78B9"/>
            <path d="M289.799 58.2001C289.799 67.8001 294.399 76.3001 300.099 77.2001C305.799 78.1001 307.199 73.1001 307.399 63.8001C307.399 61.4001 307.499 59.0002 307.699 56.5002C308.299 47.9002 305.199 43.2001 300.099 42.4001C294.999 41.6001 289.799 48.6001 289.799 58.2001Z" fill="white"/>
            <path d="M332.199 58.8001C331.21 57.5131 330.624 55.9612 330.517 54.3412C330.411 52.7212 330.787 51.1059 331.599 49.7001C339.599 36.1001 346.899 35.6001 352.099 35.9001C357.299 36.2001 361.299 41.1001 356.999 53.5001C363.099 60.1001 360.599 66.2001 351.899 67.0001C343.199 67.8001 338.499 67.1001 332.199 58.8001Z" fill="#D11583"/>
            <path d="M389.399 192.1C390.599 159.3 380.099 155.1 379.099 145.3C378.099 135.5 377.099 122.3 366.299 108.1C366.299 108.1 367.599 94.8002 368.299 85.9002C368.317 85.5498 368.257 85.1999 368.125 84.875C367.992 84.5502 367.79 84.2583 367.533 84.02C367.275 83.7816 366.969 83.6026 366.635 83.4955C366.301 83.3884 365.947 83.3559 365.599 83.4002C358.591 84.5157 351.491 84.9511 344.399 84.7002C332.199 84.3002 315.699 83.1002 298.099 100.2L303.799 108.2L303.399 108.9C303.117 109.382 302.944 109.921 302.893 110.478C302.843 111.034 302.915 111.595 303.106 112.12C303.297 112.646 303.602 113.122 303.998 113.516C304.395 113.91 304.873 114.212 305.399 114.4L306.499 114.8C306.499 114.8 290.399 124.5 295.199 146.4C299.999 168.3 294.899 189.5 302.999 196C304.638 197.303 306.584 198.161 308.651 198.494C310.717 198.827 312.835 198.622 314.799 197.9L351.899 217.7C365.399 232.6 384.599 249.3 384.599 249.3L369.799 284.6L385.399 323.9C398.699 291.3 416.199 259.3 419.299 244.5C422.399 229.7 412.399 221 389.399 192.1Z" fill="#2331B5"/>
            <path d="M374.799 303.8C382.199 280.9 390.499 260.2 392.999 254.2C393.211 253.673 393.257 253.095 393.132 252.541C393.007 251.988 392.716 251.485 392.299 251.1C370.699 230.4 365.599 223.3 365.599 223.3" stroke="#3442D9" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M317.699 178L304.299 168.9C304.299 168.9 312.399 157.8 316.899 151.1L317.999 149.4C318.042 149.299 318.105 149.208 318.185 149.132C318.265 149.057 318.359 148.998 318.462 148.961C318.565 148.923 318.675 148.907 318.785 148.914C318.895 148.921 319.001 148.95 319.099 149C319.283 149.086 319.433 149.232 319.523 149.413C319.614 149.595 319.641 149.801 319.599 150C316.699 167.7 317.699 178 317.699 178Z" fill="black"/>
            <path d="M368.099 87.6001C360.999 90.0001 351.599 89.9001 340.399 89.6001C329.199 89.3001 317.899 89.0001 299.399 102L302.399 106.3C319.499 94.2001 328.699 94.5001 340.299 94.8001C351.899 95.1001 360.099 95.2001 367.699 93.2001C367.799 91.3001 367.999 89.4001 368.099 87.6001Z" fill="#3442D9"/>
            <path d="M362.699 190.5C362.922 190.501 363.137 190.42 363.303 190.273C363.469 190.125 363.575 189.921 363.599 189.7C363.799 173.6 357.699 157.2 355.099 150.9C352.37 144.411 350.715 137.521 350.199 130.5C349.399 121.4 347.499 114.3 346.299 111.5L349.699 84.7001H347.899L344.499 111.8V112C345.599 114.4 347.599 121.5 348.399 130.7C348.927 137.88 350.581 144.933 353.299 151.6C355.999 157.8 361.999 173.9 361.799 189.6C361.799 189.839 361.894 190.068 362.063 190.236C362.232 190.405 362.461 190.5 362.699 190.5Z" fill="black"/>
            <path d="M420.499 346.5C420.143 345.972 419.668 345.534 419.112 345.222C418.557 344.91 417.936 344.731 417.299 344.7C417.899 341.1 417.199 338.1 412.499 337C407.073 335.83 401.444 336.001 396.099 337.5C386.899 313.9 368.699 251.7 355.999 206.3L314.799 197.9C314.399 209.2 321.799 234.1 327.199 255.5C332.599 276.9 344.499 321.7 347.799 337.9C349.799 347.7 352.699 359.4 352.699 359.4H390.899C402.099 359.4 416.499 355 419.299 352.8C422.099 350.6 421.499 348.3 420.499 346.5Z" fill="#2331B5"/>
            <path d="M359.199 336.3C347.399 299.1 330.199 249.1 319.699 198.3" stroke="#3442D9" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M339.599 192.1C339.799 189.2 335.799 178.3 330.999 170.4C326.199 162.5 323.799 156.9 319.299 159.5C314.799 162.1 304.299 168.9 304.299 168.9C304.299 168.9 312.399 157.8 316.899 151.1C326.599 136.5 323.199 116.4 306.499 114.8C292.799 122.4 290.699 128.9 277.699 151.8C264.699 174.7 256.999 199.1 270.999 209.2C284.999 219.3 298.499 212.3 323.699 201.2C334.599 196.5 339.499 195.1 339.599 192.1Z" fill="#2331B5"/>
            <path d="M323.899 199.7C321.099 198.4 318.599 194.2 315.599 185.6C310.599 171.1 309.299 166.7 312.399 163.7L310.499 164.9C310.05 165.171 309.676 165.552 309.414 166.006C309.151 166.461 309.009 166.975 308.999 167.5C308.899 171.3 310.799 176.9 313.999 186.2C315.999 192 317.799 195.9 319.699 198.3C320.422 199.316 321.475 200.05 322.678 200.379C323.881 200.707 325.161 200.608 326.299 200.1C325.478 200.18 324.65 200.042 323.899 199.7Z" fill="#3442D9"/>
            <path d="M330.999 170.4C326.299 162.5 323.799 156.9 319.299 159.5C314.799 162.1 291.799 176.6 291.799 176.6" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M382.699 112.9C379.611 92.1183 378.439 71.0964 379.199 50.1001C379.222 49.4297 379.087 48.7633 378.806 48.1543C378.525 47.5452 378.105 47.0104 377.58 46.5926C377.055 46.1747 376.44 45.8854 375.784 45.7478C375.127 45.6102 374.448 45.6281 373.799 45.8001L348.899 52.8001" stroke="#66BCFF" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M366.699 114.6C369.099 119.2 373.399 127.4 374.299 130.1C374.599 134.3 374.399 141.5 374.399 143.7C374.41 143.904 374.483 144.099 374.608 144.26C374.733 144.42 374.905 144.539 375.099 144.6C381.899 145.6 384.799 145.7 387.599 145.4C390.399 145.1 394.299 143.8 396.999 143C397.286 142.888 397.519 142.669 397.649 142.39C397.78 142.111 397.797 141.792 397.699 141.5C395.199 133.3 393.499 127.4 393.499 127.4C392.999 117.2 392.899 109.1 392.899 106.4C392.899 106.135 392.794 105.881 392.606 105.693C392.419 105.505 392.165 105.4 391.899 105.4C382.599 104.8 379.399 104.8 375.999 105C372.599 105.2 371.499 107.2 366.799 112.8C366.59 113.049 366.467 113.359 366.449 113.683C366.431 114.008 366.519 114.33 366.699 114.6Z" fill="white"/>
            <path d="M396.699 142.2C396.699 142.2 397.799 142.6 396.999 143C397.286 142.888 397.519 142.669 397.649 142.39C397.78 142.111 397.797 141.792 397.699 141.5C395.199 133.3 393.499 127.4 393.499 127.4C392.999 117.2 392.899 109.1 392.899 106.4C392.899 106.135 392.794 105.881 392.606 105.693C392.419 105.505 392.165 105.4 391.899 105.4C382.599 104.8 379.399 104.8 375.999 105C375.276 105.049 374.567 105.218 373.899 105.5L382.699 124.1L382.099 141.2L396.699 142.2Z" fill="#FFE75C"/>
            <path d="M383.199 141.7C384.899 147.8 386.399 152.1 390.199 152.9C393.999 153.7 398.499 149.8 404.299 144C410.099 138.2 408.099 133.3 405.599 128.7C403.099 124.1 402.699 118.3 399.599 115.9C395.762 113.078 391.639 110.665 387.299 108.7C383.799 107.2 381.699 107.2 376.399 113.2C371.099 119.2 367.999 122.7 368.899 125.1C369.799 127.5 371.299 130.1 371.299 130.1C368.899 132.5 368.899 134.5 369.899 137C370.899 139.5 371.299 141.8 376.399 141.8L383.199 141.7Z" fill="#FF78B9"/>
            <path d="M371.499 129L385.499 115.8" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M384.299 134.2C381.155 136.184 377.881 137.954 374.499 139.5C374.399 141.4 374.399 142.9 374.399 143.7C374.41 143.904 374.483 144.099 374.608 144.26C374.734 144.42 374.905 144.539 375.099 144.6C381.899 145.6 384.799 145.7 387.599 145.4C390.399 145.1 394.299 143.8 396.999 143C397.286 142.888 397.519 142.669 397.649 142.39C397.78 142.111 397.797 141.792 397.699 141.5L397.099 139.5C392.636 138.252 388.338 136.473 384.299 134.2Z" fill="white"/>
            <path d="M397.099 139.5C392.636 138.252 388.338 136.473 384.299 134.2L382.299 135.5L382.099 141C382.124 141.069 382.164 141.132 382.216 141.183C382.268 141.235 382.33 141.275 382.399 141.3L396.699 142.2C396.699 142.2 397.799 142.6 396.999 143C397.298 142.892 397.547 142.679 397.699 142.4C397.699 142.3 397.799 142.1 397.799 142C397.815 141.827 397.78 141.654 397.699 141.5L397.099 139.6V139.5Z" fill="#FFE75C"/>
            <path d="M322.799 162C325.945 158.337 328.264 154.04 329.599 149.4C330.599 146.4 331.699 142.1 332.099 140.5C332.499 138.9 334.399 138 336.699 138.5C338.999 139 339.999 139.4 339.599 143.5C339.199 147.6 338.299 157.3 338.299 157.3L322.799 162Z" fill="#FF78B9"/>
            <path d="M368.799 164.4C367.299 160.3 366.599 158.2 365.299 156.5C363.999 154.8 362.799 154.5 356.999 154.2C354.699 154.1 350.299 154.2 347.399 154.3C353.099 152.8 359.199 151.5 360.499 151.1C361.799 150.7 364.399 150.3 363.499 147.8C362.599 145.3 361.799 144.7 360.399 144.7C356.811 144.631 353.225 144.933 349.699 145.6C344.554 146.608 339.503 148.046 334.599 149.9C331.699 151.1 327.999 156.5 322.799 162C325.099 170.1 330.599 182.5 336.199 188.4C343.599 185.5 347.999 179.1 349.799 176.7C359.099 175.1 362.299 176.1 367.499 176.8C370.699 177.2 370.999 174.9 371.199 173.8C371.399 172.7 370.099 168.2 368.799 164.4Z" fill="#FF78B9"/>
            <path d="M292.799 58.3001C292.499 62.5001 293.899 66.0001 295.899 66.2001C297.899 66.4001 298.899 64.8001 298.999 60.9001C299.045 59.2515 299.246 57.611 299.599 56.0001C300.299 52.5001 298.799 51.2001 297.099 51.1001C295.399 51.0001 293.099 54.2001 292.799 58.3001Z" fill="black"/>
            <path d="M327.099 8.90013C329.174 8.46917 331.335 8.81049 333.177 9.86C335.018 10.9095 336.413 12.595 337.099 14.6001C338.599 19.3001 339.899 26.4001 331.899 27.7001C328.499 28.2001 324.999 26.7001 319.599 22.5001C315.699 19.4001 311.499 18.3001 302.399 23.0001C297.499 19.6001 299.899 10.9001 313.199 15.3001C307.399 7.80013 309.099 2.60013 313.299 1.10013C317.499 -0.399871 323.799 0.400132 327.099 8.90013Z" fill="#2A39D4"/>
            <path d="M327.099 23.3001C324.199 19.7001 321.799 14.9001 319.799 10.8001C317.799 6.70009 315.799 2.4001 313.599 1.1001H313.299C312.513 1.37475 311.796 1.8185 311.199 2.4001L311.899 2.20009C313.799 2.40009 315.899 6.80009 318.199 11.5001C320.499 16.2001 322.699 20.6001 325.699 24.4001C326.707 25.5928 327.848 26.6664 329.099 27.6001C330.021 27.7827 330.967 27.8165 331.899 27.7001H331.999C330.157 26.484 328.506 25.0012 327.099 23.3001Z" fill="black"/>
            <path d="M337.199 24.7002C336.77 25.3286 336.228 25.8713 335.599 26.3002C335.125 19.6613 332.16 13.4465 327.299 8.90017C328.052 8.71613 328.833 8.68214 329.599 8.80016C333.784 13.1399 336.45 18.7182 337.199 24.7002Z" fill="black"/>
            <path d="M347.399 335.7C347.499 336.5 347.699 337.2 347.799 337.9C349.799 347.7 352.699 359.4 352.699 359.4H390.899C402.099 359.4 416.499 355 419.299 352.8C422.099 350.6 421.499 348.3 420.499 346.5C420.142 345.972 419.668 345.534 419.112 345.222C418.557 344.909 417.936 344.731 417.299 344.7C417.899 341.1 417.199 338.1 412.499 337C407.073 335.829 401.444 336.001 396.099 337.5C394.699 333.8 392.999 329.2 391.199 323.9C374.399 323.6 357.299 322.2 347.399 335.7Z" fill="#45AAF7"/>
            <path d="M347.399 335.7C347.499 336.5 347.699 337.2 347.799 337.9C349.799 347.7 352.699 359.4 352.699 359.4H390.899C402.099 359.4 416.499 355 419.299 352.8C422.099 350.6 421.499 348.3 420.499 346.5C420.142 345.972 419.668 345.534 419.112 345.222C418.557 344.909 417.936 344.731 417.299 344.7C417.899 341.1 417.199 338.1 412.499 337C407.073 335.829 401.444 336.001 396.099 337.5C394.699 333.8 392.999 329.2 391.199 323.9C374.399 323.6 357.299 322.2 347.399 335.7Z" fill="#45AAF7"/>
            <path d="M396.099 337.5L394.799 337.8H394.599L383.699 340C383.524 339.768 383.42 339.49 383.399 339.2C382.577 336.372 382.14 333.445 382.099 330.5C381.999 324.4 378.799 323.8 373.299 323.9L373.599 323.2C374.338 321.262 375.656 319.598 377.374 318.435C379.091 317.272 381.125 316.667 383.199 316.7H392.299C392.832 316.707 393.356 316.829 393.837 317.058C394.318 317.287 394.744 317.616 395.085 318.025C395.427 318.433 395.677 318.91 395.817 319.423C395.958 319.937 395.986 320.475 395.899 321C394.799 325.7 394.499 331.5 395.599 335.8C395.706 336.383 395.873 336.953 396.099 337.5Z" fill="#8CD9FF"/>
            <path d="M357.499 343.6C356.141 343.144 354.704 342.971 353.277 343.092C351.849 343.212 350.462 343.623 349.199 344.3C349.285 344.91 349.418 345.512 349.599 346.1C350.676 345.426 351.888 344.996 353.15 344.841C354.411 344.685 355.691 344.808 356.899 345.2C362.499 347.1 366.599 354.4 368.699 359.4H370.599C368.399 354.2 363.999 345.8 357.499 343.6Z" fill="#5865F2"/>
            <path d="M420.199 351.9L419.299 352.8C416.499 355 402.099 359.4 390.899 359.4H368.099V359C394.299 358.6 412.099 347.7 418.199 347.7C420.199 347.7 421.399 350.7 420.199 351.9Z" fill="black"/>
            <path d="M395.099 138.1C396.524 140.012 398.578 141.358 400.899 141.9" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M351.199 130.4C350.204 130.405 349.243 130.039 348.503 129.373C347.764 128.708 347.299 127.79 347.199 126.8L346.399 117.7H348.199L353.499 126.4C353.723 126.806 353.838 127.263 353.833 127.727C353.829 128.19 353.705 128.645 353.474 129.047C353.243 129.449 352.912 129.784 352.514 130.021C352.115 130.258 351.663 130.389 351.199 130.4Z" fill="#FFE75C"/>
            <path d="M306.499 114.8H316.299" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M362.199 228.4C360.999 224.1 359.199 218 359.199 218C367.24 212.757 374.894 206.942 382.099 200.6C382.099 200.6 373.799 219 370.099 236.1L362.199 228.4Z" fill="black"/>
            <path d="M384.599 249.3L394.299 248.9" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M396.099 337.5L394.799 337.8H394.599C390.254 338.826 385.979 340.129 381.799 341.7H381.499C381.322 341.691 381.151 341.628 381.008 341.521C380.866 341.415 380.758 341.268 380.699 341.1C380.652 340.996 380.627 340.883 380.627 340.768C380.627 340.654 380.651 340.54 380.698 340.436C380.746 340.332 380.815 340.239 380.901 340.164C380.988 340.088 381.089 340.033 381.199 340L383.399 339.1C387.391 337.712 391.465 336.577 395.599 335.7C395.698 336.317 395.866 336.921 396.099 337.5Z" fill="black"/>
            <path d="M369.099 165.2L368.799 164.4L368.399 163.4C359.699 162.5 350.599 161.9 341.699 162.8C341.581 162.813 341.467 162.85 341.362 162.907C341.258 162.964 341.166 163.042 341.092 163.135C341.018 163.227 340.963 163.334 340.93 163.448C340.897 163.562 340.886 163.682 340.899 163.8C340.912 163.918 340.949 164.033 341.006 164.137C341.064 164.241 341.141 164.333 341.234 164.407C341.327 164.482 341.433 164.537 341.547 164.57C341.661 164.603 341.781 164.613 341.899 164.6C350.899 163.6 360.199 164.3 369.099 165.2Z" fill="black"/>
            <path d="M301.199 53.8002L295.399 56.6002L303.499 60.3002L301.199 53.8002Z" fill="white"/>
            <path d="M420.199 351.9C420.799 351.1 420.099 350.6 418.199 350.6C412.099 350.6 394.299 359 368.099 359.4H352.699L351.599 355C363.599 355 376.699 354.8 388.899 350.9C401.099 347 409.199 343.7 417.299 344.7C417.936 344.731 418.557 344.909 419.112 345.222C419.668 345.534 420.142 345.972 420.499 346.5C421.041 347.311 421.305 348.276 421.251 349.25C421.197 350.224 420.828 351.154 420.199 351.9Z" fill="white"/>
            <path d="M388.899 319.9H390.099C392.899 319.9 394.799 320 395.399 320.7C395.999 321.4 395.699 321.7 395.499 322.7C395.585 322.123 395.719 321.555 395.899 321C395.986 320.378 395.824 319.747 395.451 319.242C395.077 318.737 394.52 318.399 393.899 318.3H388.899C384.299 318.3 379.099 318.3 376.399 324.2C377.013 324.184 377.624 324.286 378.199 324.5C380.499 319.8 384.599 319.9 388.899 319.9Z" fill="#3442D9"/>
            <path d="M351.199 48.3002C349.899 50.4002 347.599 52.8002 342.499 53.8002C349.499 53.0002 352.499 56.9002 352.499 56.9002" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M220.9 359.4H154.4V345.4C154.389 341.782 155.788 338.303 158.3 335.7L164.9 329.3C165.746 328.503 166.421 327.542 166.885 326.477C167.35 325.411 167.593 324.262 167.6 323.1V307.1C167.608 303.896 168.699 300.789 170.696 298.284C172.693 295.778 175.479 294.022 178.6 293.3C189.3 290.6 200.4 290.1 209.5 291.9C212.721 292.567 215.613 294.323 217.689 296.873C219.766 299.424 220.9 302.611 220.9 305.9V359.4Z" fill="#45AAF7"/>
            <path d="M171.5 332.6H171.9C172.057 332.609 172.213 332.577 172.354 332.506C172.494 332.436 172.613 332.331 172.7 332.2C175.7 326.4 175.7 317.4 175.7 307.8C175.7 298.2 176.6 294.6 183 292.2L179.6 292.9C178.723 293.098 177.901 293.488 177.192 294.041C176.484 294.594 175.906 295.297 175.5 296.1C174.1 298.9 173.9 302.6 173.9 307.8C173.9 317.2 173.9 326 171.1 331.4C171.033 331.501 170.989 331.617 170.972 331.738C170.956 331.859 170.968 331.982 171.006 332.098C171.045 332.214 171.109 332.319 171.195 332.406C171.281 332.493 171.385 332.56 171.5 332.6Z" fill="#BAE8FF"/>
            <path d="M220.901 305.9C220.895 302.597 219.747 299.399 217.651 296.847C215.554 294.295 212.639 292.547 209.401 291.9C204.053 290.98 198.617 290.678 193.2 291C180.4 291.9 182.301 300 182.301 311.8V321.9C182.306 325.275 181.409 328.59 179.7 331.5C177.8 334.6 177.301 338.4 177.301 345.8V359.4H220.901V305.9Z" fill="#3F99F2"/>
            <path d="M204.9 343.3C204.3 325.9 196.4 310.7 188.4 296.2C188.196 295.912 188.076 295.573 188.053 295.221C188.031 294.869 188.107 294.517 188.273 294.205C188.439 293.894 188.689 293.635 188.994 293.457C189.299 293.28 189.648 293.191 190 293.2H192C192.42 293.223 192.828 293.344 193.192 293.552C193.557 293.76 193.868 294.051 194.1 294.4C202.3 309.5 210.9 325.2 211.5 343.3C211.8 352.4 204.9 359.4 195.7 359.4H189.1C198.3 359.4 205.2 352.4 204.9 343.3Z" fill="#3543DE"/>
            <path d="M223.7 343.3C223.1 325.9 215.1 310.7 207.2 296.2C206.996 295.912 206.876 295.573 206.853 295.221C206.83 294.869 206.907 294.517 207.073 294.205C207.239 293.894 207.488 293.635 207.794 293.457C208.099 293.28 208.448 293.191 208.801 293.2H210.801C211.208 293.214 211.606 293.331 211.956 293.541C212.306 293.751 212.597 294.047 212.801 294.4C221.101 309.5 229.601 325.2 230.301 343.3C230.398 345.415 230.057 347.527 229.302 349.504C228.546 351.481 227.391 353.281 225.908 354.792C224.425 356.303 222.647 357.492 220.684 358.285C218.722 359.078 216.617 359.457 214.5 359.4H207.9C210.017 359.457 212.122 359.078 214.084 358.285C216.047 357.492 217.825 356.303 219.308 354.792C220.791 353.281 221.946 351.481 222.702 349.504C223.458 347.527 223.797 345.415 223.7 343.3Z" fill="#3543DE"/>
            <path d="M207.4 316.3L227.7 319.8C229.3 324 230.1 326.8 230.3 329.5C230.5 332.2 227.9 333.7 223.9 335.3C221.029 336.499 218.389 338.192 216.1 340.3C210.3 345.4 205.4 348.5 196.9 348.5C195.2 348.5 194 348.6 193 347.5C191.938 346.304 191.31 344.784 191.22 343.187C191.129 341.59 191.58 340.009 192.5 338.7C193.528 337.305 194.778 336.089 196.2 335.1C199.222 333.14 201.775 330.537 203.676 327.478C205.578 324.419 206.781 320.978 207.2 317.4C207.3 316.9 207.3 316.6 207.4 316.3Z" fill="#DE3A93"/>
            <path d="M223.901 335.3C225.587 334.669 227.17 333.793 228.6 332.7C230.5 330.9 229.7 329.2 225.6 329.3C221.5 329.4 217.2 332.6 208.6 337.9C200 343.2 187.201 348.5 196.901 348.5C206.601 348.5 210.3 345.4 216.1 340.3C218.389 338.192 221.029 336.499 223.901 335.3Z" fill="black"/>
            <path d="M205.2 310.2C203.7 310.5 203.9 312.2 204.7 313.9C203.1 316.9 204.101 318.1 206.901 319.3C212.558 321.224 218.534 322.038 224.501 321.7C230.601 321.1 230.2 319.1 229.6 317.1C231.7 315.3 231.401 313.2 229.301 312.3C227.201 311.4 215.9 307.9 205.2 310.2Z" fill="#66BCFF"/>
            <path d="M250.301 205.8H224.1V220.09H250.301V205.8Z" fill="white"/>
            <path d="M303.6 206.1C304.101 208.132 304.133 210.252 303.693 212.298C303.254 214.345 302.354 216.264 301.063 217.912C299.772 219.559 298.123 220.892 296.241 221.808C294.359 222.724 292.294 223.2 290.2 223.2H261.6L248.2 230.7V230.3C242.1 230.3 238.601 228.3 241.301 205.9C240.101 199.6 234.501 174.8 231.401 156.3C228.601 139.4 234.901 133.2 252.301 123.4C259.701 101.4 278.9 102.7 282.6 115.9C286.3 129.1 298.5 185.2 303.6 206.1Z" fill="#66BCFF"/>
            <path d="M249.901 237.8L245.301 238.6C245.076 238.644 244.863 238.737 244.676 238.871C244.49 239.004 244.335 239.177 244.221 239.376C244.107 239.575 244.037 239.796 244.017 240.025C243.996 240.253 244.024 240.484 244.101 240.7L245.101 243.2C231.201 244 219.101 245.2 214.301 249.4C209.501 253.6 209.501 260 207.301 279.1C205.101 298.2 201.501 309.6 207.301 312.2C213.189 314.728 219.63 315.693 226.001 315C232.401 314.4 233.001 311.1 234.301 301.2C235.601 291.3 238.7 269.3 238.7 269.3H289.2C301.7 269.3 309.101 261.1 310.801 254.1C312.001 249.1 310.401 237.3 307.501 223.3H261.601L248.2 230.8L249.2 235.1L249.901 237.8Z" fill="#3442D9"/>
            <path d="M292 223.5C297 248.5 298 267.3 279 275.9" stroke="#2330BF" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M252.7 210.9C252.7 210.9 254.8 223.2 257.7 237.8C261 254.6 260.1 254 237.2 255.5C217.1 256.8 218.9 255.8 214.7 278.7C213.1 287.7 210.3 304 211.1 313C211.1 313 210.2 318.3 210.4 319.4" stroke="#2467D4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M238.7 269.3C227.6 267.6 222.2 257.6 222.2 257.6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M238.7 269.3C236.429 267.962 234.445 266.185 232.866 264.073C231.287 261.962 230.143 259.557 229.5 257" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M303.6 206.1C301 195.3 296.4 175.3 292.1 156.6L269.7 154.9L252.401 167.1L242.401 180.5L237.301 187.4C239.101 196 240.6 203 241.2 206C238.5 228.4 242 230.4 248.1 230.4L265.801 222.9L306.801 220C305.801 215.5 304.7 210.8 303.6 206.1Z" fill="#EB459F"/>
            <path d="M257.77 194.842C259.031 187.382 258.377 181.05 256.307 180.7C254.238 180.35 251.538 186.114 250.276 193.575C249.014 201.035 249.669 207.366 251.738 207.716C253.807 208.066 256.508 202.302 257.77 194.842Z" fill="black"/>
            <path d="M244.7 176.3C245.7 174.5 246.801 172.7 247.901 171.1C259.201 154.8 269.8 150.4 280 150.4H292.7C292.89 150.405 293.075 150.455 293.242 150.545C293.408 150.635 293.551 150.764 293.658 150.92C293.765 151.076 293.834 151.255 293.859 151.443C293.884 151.631 293.864 151.822 293.801 152L292.1 156.6C272.6 156.6 265.7 156 249.5 173L242.401 180.5L244.7 176.3Z" fill="#D11583"/>
            <path d="M306.801 220H273.7C266.3 220 257.2 219.6 248.1 230.4L249.2 235.1C254.5 230 259.4 227.8 270 227.8H308.401L306.801 220Z" fill="black"/>
            <path d="M249.5 173.6C242.8 174.8 237.5 178.3 234.5 184.5C233.648 186.22 232.418 187.724 230.9 188.9L212.9 202C210.877 203.436 209.467 205.579 208.948 208.005C208.43 210.431 208.841 212.963 210.1 215.1C210.978 216.6 212.235 217.842 213.744 218.702C215.254 219.562 216.963 220.01 218.7 220H226.2C241.8 211.3 249 205.4 252.7 202C256.4 198.6 260.5 192.5 259.8 185.5C259.1 178.5 257.1 172.2 249.5 173.6Z" fill="white"/>
            <path d="M255.1 121.9C252.7 117.9 249.5 112.6 248.3 110.1C247.1 107.6 244.2 108.2 248.3 119.4C238.3 115.6 236.2 117 235.7 118.4C235.2 119.8 237.4 120.5 241.6 122.9C245.8 125.3 250.3 128.2 250.3 128.2L255.1 121.9Z" fill="#66BCFF"/>
            <path d="M265.5 122.2C267.2 120.1 267.801 117.8 266.801 117C265.801 116.2 264.7 116.6 263.2 118.6C262.553 119.413 261.852 120.182 261.1 120.9C259.4 122.5 259.8 123.7 260.6 124.4C261.4 125.1 263.8 124.3 265.5 122.2Z" fill="black"/>
            <path d="M282.5 116C282.5 116 270.6 110.6 265.5 107.3C260.4 104 257.8 99.1 260.1 97.8C262.4 96.5 266.801 99.6 269.901 103.6C269.801 96.5 271.001 95.0001 272.901 94.9001C274.801 94.8001 275 97.6 275.1 101.6C275.2 105.6 275.901 107.2 278.901 109.9C280.697 111.517 281.953 113.646 282.5 116Z" fill="#3442D9"/>
            <path d="M238.801 149.2C236.001 149.2 233.601 146.8 235.801 142.6C238.001 138.4 245.2 134.2 248.7 132.6C252.2 131 253.801 132.6 253.901 133.8C254.001 135 253.5 136.8 251 140C248.5 143.2 243.901 149.2 238.801 149.2Z" fill="white"/>
            <path d="M241 148.9L239.301 149.2L236.901 141L238.301 139.5L241 148.9Z" fill="#8CD9FF"/>
            <path d="M247.2 144.5L245.801 145.9L243 135.8L244.5 134.8L247.2 144.5Z" fill="#8CD9FF"/>
            <path d="M252.801 137.5L251.5 139.3L249.5 132.3L251.2 132L252.801 137.5Z" fill="#8CD9FF"/>
            <path d="M250.6 131.1C252.451 131.984 254.355 132.753 256.301 133.4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M248.1 242.5L259.1 255.9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M256.7 238.5H252.801L251.6 233L250.5 227.9C251.513 226.907 252.582 225.972 253.7 225.1L255 230.7L256.7 238.5Z" fill="#3442D9"/>
            <path d="M274 233.2H270.1L268.901 227.8L267.301 220.2H271.2L272.801 227.9L274 233.2Z" fill="#3442D9"/>
            <path d="M305.901 233H302L300.801 227.8L299.2 220H303.1L304.7 227.8L305.901 233Z" fill="#3442D9"/>
            <path d="M295.2 169.8C293.286 168.282 290.941 167.407 288.501 167.3C283.301 167.3 280.401 172.4 279.401 177.3C279.24 178.086 278.815 178.792 278.196 179.302C277.577 179.812 276.802 180.093 276.001 180.1H275.301C274.85 180.017 274.42 179.844 274.038 179.59C273.655 179.337 273.328 179.009 273.076 178.626C272.824 178.243 272.651 177.813 272.57 177.362C272.488 176.91 272.499 176.447 272.601 176C274.501 166.5 280.701 160.4 288.501 160.4C289.791 160.416 291.038 160.87 292.038 161.688C293.037 162.505 293.729 163.638 294.001 164.9L295.2 169.8Z" fill="white"/>
            <path d="M303.6 206.1C303.821 206.994 303.857 207.924 303.707 208.832C303.557 209.741 303.225 210.61 302.729 211.386C302.233 212.162 301.585 212.829 300.824 213.347C300.063 213.866 299.204 214.224 298.301 214.4L295.801 214.6C290.101 214.6 284.2 211.2 279.6 205.3C279.325 204.932 279.124 204.514 279.01 204.069C278.896 203.623 278.871 203.16 278.936 202.705C279.001 202.25 279.155 201.812 279.389 201.417C279.623 201.021 279.933 200.676 280.301 200.4C280.663 200.111 281.081 199.899 281.528 199.777C281.975 199.656 282.443 199.627 282.902 199.692C283.361 199.758 283.801 199.916 284.197 200.159C284.592 200.401 284.934 200.721 285.2 201.1C288.4 205.2 292.301 207.7 295.801 207.7C299.301 207.7 301.401 205.7 302.901 203.1C303.101 204.2 303.4 205.2 303.6 206.1Z" fill="#2331B5"/>
            <path d="M296.9 177.5C296.194 177.504 295.507 177.727 294.933 178.139C294.36 178.551 293.929 179.132 293.7 179.8L289.5 191.1C289.133 192.161 289.141 193.316 289.523 194.372C289.906 195.428 290.639 196.32 291.6 196.9C292.035 197.167 292.525 197.334 293.032 197.386C293.54 197.439 294.053 197.376 294.534 197.203C295.014 197.03 295.449 196.751 295.807 196.387C296.165 196.023 296.436 195.583 296.6 195.1L299.301 187.8C298.601 184.5 297.8 181.1 296.9 177.5Z" fill="#5865F2"/>
            <path d="M291.6 196.9C290.639 196.32 289.906 195.428 289.523 194.372C289.141 193.316 289.133 192.161 289.5 191.1L290.7 188C290.901 187.459 291.217 186.968 291.625 186.56C292.034 186.153 292.526 185.839 293.067 185.639C293.609 185.44 294.187 185.36 294.763 185.405C295.338 185.45 295.897 185.619 296.4 185.9C297.223 186.381 297.853 187.132 298.181 188.027C298.509 188.921 298.516 189.901 298.2 190.8L296.6 195.1C296.436 195.583 296.165 196.023 295.807 196.387C295.449 196.751 295.014 197.03 294.534 197.203C294.053 197.376 293.54 197.439 293.032 197.386C292.525 197.334 292.035 197.168 291.6 196.9Z" fill="#FFE75C"/>
            <path d="M238.6 212.9C235.398 215.126 231.8 216.721 228 217.6" stroke="#A3D7FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M277.301 359.4H259.801C249.101 359.4 239.2 350.8 237.7 340.2C236.2 329.6 243.001 324 254.501 321.5L256.501 321C258.12 320.661 259.549 319.717 260.497 318.361C261.445 317.004 261.839 315.337 261.601 313.7C261.406 312.107 260.677 310.626 259.533 309.5C258.389 308.374 256.897 307.669 255.301 307.5L252.401 307.2C240.001 305.9 231.801 298.6 230.301 288C228.801 277.4 236.201 269.3 246.601 269.3H264.101C274.801 269.3 284.7 277.9 286.2 288.5L293.601 340.7C295.001 351 287.701 359.4 277.301 359.4Z" fill="#ADB4FF"/>
            <path d="M297.6 359.4H280.1C269.4 359.4 259.5 350.8 258 340.2C257.391 336.299 258.235 332.311 260.374 328.992C262.513 325.673 265.796 323.256 269.6 322.2C271.004 321.823 272.22 320.941 273.013 319.723C273.807 318.505 274.123 317.037 273.9 315.6L273.4 311.8C273.209 310.591 272.658 309.468 271.82 308.576C270.982 307.685 269.895 307.066 268.7 306.8C259.6 304.9 251.9 297.2 250.6 288C249.3 278.8 256.5 269.3 266.9 269.3H284.4C295.1 269.3 305 277.9 306.5 288.5L313.9 340.7C315.3 351 308 359.4 297.6 359.4Z" fill="white"/>
            <path d="M293.2 286.9C293.273 287.4 293.236 287.909 293.093 288.393C292.949 288.878 292.703 289.325 292.37 289.705C292.038 290.085 291.627 290.389 291.166 290.595C290.705 290.801 290.205 290.905 289.7 290.9H271.301C270.189 290.869 269.123 290.454 268.284 289.724C267.445 288.994 266.885 287.996 266.7 286.9V286.3C266.609 285.811 266.63 285.307 266.761 284.827C266.892 284.347 267.13 283.903 267.457 283.528C267.784 283.153 268.192 282.856 268.65 282.661C269.107 282.466 269.603 282.377 270.1 282.4H288.6C289.697 282.426 290.752 282.828 291.589 283.537C292.426 284.247 292.995 285.222 293.2 286.3V286.9Z" fill="#66BCFF"/>
            <path d="M285.2 294.3C285.289 294.794 285.267 295.302 285.137 295.786C285.006 296.271 284.769 296.72 284.444 297.103C284.119 297.485 283.714 297.791 283.257 297.998C282.8 298.205 282.302 298.308 281.801 298.3H281.301C280.189 298.269 279.123 297.854 278.284 297.124C277.445 296.395 276.885 295.396 276.7 294.3L274.501 278.9C274.426 278.405 274.463 277.9 274.607 277.42C274.75 276.941 274.998 276.499 275.333 276.126C275.667 275.754 276.079 275.46 276.541 275.265C277.002 275.07 277.5 274.98 278.001 275H278.501C279.598 275.026 280.653 275.428 281.49 276.137C282.327 276.847 282.895 277.822 283.1 278.9L285.2 294.3Z" fill="#66BCFF"/>
            <path d="M287.501 317.5C287.528 317.705 287.514 317.913 287.457 318.112C287.401 318.311 287.304 318.496 287.172 318.656C287.041 318.816 286.878 318.947 286.693 319.04C286.509 319.134 286.307 319.188 286.1 319.2H285.801C285.341 319.164 284.906 318.979 284.563 318.671C284.22 318.364 283.987 317.953 283.901 317.5L283.001 311.1C282.97 310.891 282.986 310.679 283.049 310.477C283.112 310.276 283.22 310.092 283.364 309.938C283.508 309.784 283.685 309.665 283.882 309.589C284.079 309.513 284.29 309.483 284.501 309.5H284.7C285.156 309.494 285.599 309.653 285.948 309.947C286.297 310.24 286.529 310.65 286.6 311.1L287.501 317.5Z" fill="#ADB4FF"/>
            <path d="M287.1 337.7C286.003 337.674 284.949 337.272 284.112 336.563C283.275 335.853 282.706 334.878 282.501 333.8V333.2C282.409 332.711 282.43 332.207 282.561 331.727C282.692 331.247 282.93 330.803 283.257 330.428C283.584 330.053 283.992 329.756 284.45 329.561C284.907 329.366 285.403 329.277 285.901 329.3H286.401C287.484 329.321 288.528 329.709 289.363 330.399C290.198 331.088 290.775 332.041 291.001 333.1V333.8C291.075 334.295 291.038 334.8 290.894 335.28C290.751 335.759 290.503 336.201 290.168 336.574C289.834 336.946 289.422 337.24 288.96 337.435C288.499 337.63 288.001 337.72 287.501 337.7H287.1Z" fill="#66BCFF"/>
            <path d="M289.7 352.6H289.2C288.098 352.593 287.033 352.198 286.192 351.485C285.352 350.772 284.788 349.786 284.6 348.7V347.8C284.558 347.32 284.617 346.837 284.775 346.382C284.933 345.928 285.186 345.511 285.517 345.162C285.848 344.812 286.249 344.536 286.695 344.353C287.14 344.17 287.619 344.084 288.1 344.1H288.6C289.684 344.121 290.727 344.509 291.563 345.198C292.398 345.888 292.975 346.84 293.2 347.9V348.6C293.291 349.103 293.267 349.619 293.131 350.111C292.995 350.603 292.749 351.058 292.413 351.442C292.077 351.827 291.659 352.13 291.189 352.331C290.719 352.531 290.211 352.623 289.7 352.6Z" fill="#FF78B9"/>
            <path d="M287.6 337.7H287.1C286.003 337.674 284.949 337.272 284.112 336.563C283.275 335.853 282.706 334.878 282.501 333.8V333.2C282.409 332.711 282.43 332.207 282.561 331.727C282.692 331.247 282.93 330.803 283.257 330.428C283.584 330.053 283.992 329.756 284.45 329.561C284.907 329.366 285.403 329.277 285.901 329.3H286.401C287.484 329.321 288.528 329.709 289.363 330.399C290.198 331.088 290.775 332.041 291.001 333.1V333.8C291.073 334.287 291.039 334.783 290.901 335.255C290.763 335.727 290.524 336.164 290.2 336.534C289.877 336.905 289.477 337.201 289.028 337.403C288.58 337.604 288.092 337.705 287.6 337.7Z" fill="#5865F2"/>
            <path d="M297.1 345.1C296.003 345.074 294.949 344.672 294.112 343.963C293.275 343.253 292.706 342.278 292.501 341.2V340.6C292.409 340.111 292.43 339.607 292.561 339.127C292.692 338.647 292.93 338.203 293.257 337.828C293.584 337.453 293.992 337.156 294.45 336.961C294.907 336.766 295.403 336.677 295.901 336.7H296.401C297.484 336.721 298.528 337.109 299.363 337.799C300.198 338.488 300.775 339.441 301.001 340.5V341.2C301.092 341.689 301.071 342.193 300.94 342.673C300.809 343.153 300.571 343.597 300.244 343.972C299.917 344.347 299.509 344.644 299.051 344.839C298.594 345.034 298.098 345.124 297.6 345.1H297.1Z" fill="#66BCFF"/>
            <path d="M297.6 345.1H297.1C296.003 345.074 294.949 344.672 294.112 343.963C293.275 343.253 292.706 342.278 292.501 341.2V340.6C292.409 340.111 292.43 339.607 292.561 339.127C292.692 338.647 292.93 338.203 293.257 337.828C293.584 337.453 293.992 337.156 294.45 336.961C294.907 336.766 295.403 336.677 295.901 336.7H296.401C297.484 336.721 298.528 337.109 299.363 337.799C300.198 338.488 300.775 339.441 301.001 340.5V341.2C301.092 341.689 301.071 342.193 300.94 342.673C300.809 343.153 300.571 343.597 300.244 343.972C299.917 344.347 299.509 344.644 299.051 344.839C298.594 345.034 298.098 345.124 297.6 345.1Z" fill="#5865F2"/>
            <path d="M279.7 345.3H279.1C278.001 345.265 276.948 344.846 276.126 344.115C275.304 343.384 274.764 342.388 274.6 341.3V340.4C274.542 339.926 274.588 339.445 274.734 338.99C274.88 338.535 275.123 338.117 275.446 337.765C275.769 337.413 276.165 337.136 276.606 336.952C277.047 336.768 277.523 336.682 278 336.7H278.6C279.68 336.749 280.712 337.16 281.528 337.868C282.345 338.576 282.899 339.538 283.1 340.6V341.2C283.206 341.701 283.197 342.22 283.074 342.717C282.951 343.214 282.717 343.677 282.391 344.071C282.064 344.465 281.652 344.781 281.186 344.994C280.72 345.206 280.212 345.311 279.7 345.3Z" fill="#FF78B9"/>
            <path d="M117.4 339.4L107.7 334C115.314 321.461 123.697 309.404 132.8 297.9C114.9 304.4 96.1 312.5 75.3 312.5H40.5C38.4 308.2 37.8 303.8 37.8 297.2L80.3 281.7L96.4 285L96.9 289.6C112.3 285.7 128.2 281.2 139.7 278.5C154.2 275.1 159.1 282.4 150.7 294.6C134.5 318.2 117.4 339.4 117.4 339.4Z" fill="#29CC7A"/>
            <path d="M50.1 293.2C50.1 301.7 50.1 307.7 54.2 311.1C55.1863 311.995 56.4685 312.493 57.8 312.5H62C51.8 310.8 51.8 305.3 51.9 293.2V292.4C51.9 292.3 51.8 292.2 51.8 292.1L50.1 292.7V293.2Z" fill="#15AD75"/>
            <path d="M117.4 334.9C133 314.3 143 300 147.2 293.3C151.4 286.6 152.8 282.4 131.6 289.6C110.4 296.8 94.2999 303.3 89.5999 301.5C84.8999 299.7 86.6 294.9 86.9 286.4" stroke="#15AD75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M55 237.8C47.8307 226.764 41.9268 214.957 37.4 202.6C36.7598 200.545 36.6163 198.368 36.9814 196.247C37.3465 194.126 38.2096 192.122 39.5 190.4C46.8 181.6 56.3999 176.9 69.1999 174.7C70.5934 174.546 71.992 174.941 73.0999 175.8C88.2999 189.1 92.8 204.1 95.4 218.7C95.4657 219.543 95.3166 220.388 94.9669 221.158C94.6171 221.927 94.0781 222.595 93.4 223.1C85.9 227.7 81.9 229.1 81.9 229.1L83.4 240.2L55 237.8Z" fill="#FFE75C"/>
            <path d="M69.5 200.5C76.9935 201.196 84.4396 202.331 91.8 203.9L95.9 205C96.0512 205.042 96.1889 205.123 96.2993 205.234C96.4097 205.345 96.489 205.484 96.5294 205.635C96.5698 205.787 96.57 205.947 96.5298 206.098C96.4895 206.25 96.4103 206.388 96.3 206.5L95.4 207.3L97 213.5C97.5174 215.396 97.385 217.412 96.6238 219.224C95.8626 221.036 94.5165 222.542 92.8 223.5L89.1 225.6L69.5 200.5Z" fill="#FFE75C"/>
            <path d="M72.7054 207.868C74.6078 206.541 74.2288 202.711 71.8589 199.314C69.489 195.916 66.0256 194.238 64.1232 195.565C62.2208 196.893 62.5998 200.722 64.9697 204.12C67.3396 207.517 70.803 209.195 72.7054 207.868Z" fill="#FF78B9"/>
            <path d="M84.5 194C83.3 192.9 82 192.6 81.5 193.1C81 193.6 81.3 194.4 82.4 195.2L83.6999 196.5C84.5999 197.5 85.2999 197.3 85.6999 196.8C86.0999 196.3 85.7 195 84.5 194Z" fill="black"/>
            <path d="M44.8001 185.1C43.2001 169 44.8 163.6 48.2 158.9C51.6 154.2 53.6 155.6 53 161.8C52.6361 166.661 52.6695 171.544 53.1 176.4C56.0578 168.824 60.3603 161.846 65.8001 155.8C67.4116 153.846 69.5707 152.418 72 151.7C74.4 151.1 75.7 152.5 75 154.8C73.8 158.7 70.9 164.9 63.1 176L63.9 180.4L44.8001 185.1Z" fill="#FFE75C"/>
            <path d="M91.5 247.3C100 250.2 94 276.8 94 276.8L95.1999 277.7C96.1816 278.451 96.8761 279.516 97.1673 280.717C97.4585 281.918 97.3289 283.183 96.8 284.3L96.4 285C90.5 285 86.5999 285.5 78.5999 291.2C70.5999 296.9 65.5 297.2 57.3 297.2H37.8V290.6C30.5 287.7 29.8999 284.2 32.6999 279.7C34.1999 277.1 36.6 273.5 38.4 270.8C39.0909 269.768 39.5703 268.609 39.8106 267.39C40.0509 266.171 40.0473 264.917 39.8 263.7C38.2 255.1 39.5 250 39.5 250L60.6999 244.3L87.5999 243.2L91.5 247.3Z" fill="#2331B5"/>
            <path d="M90.8732 271.794C93.4362 271.116 94.3359 266.109 92.8828 260.609C91.4297 255.109 88.174 251.2 85.611 251.877C83.048 252.554 82.1482 257.562 83.6013 263.061C85.0544 268.561 88.3102 272.471 90.8732 271.794Z" fill="black"/>
            <path d="M103.9 334.6C106.3 332.1 111.5 327.9 111.5 327.9C114.8 327.9 119.8 328.5 122 337.6L124.7 348.3C135 350.2 135.1 353.1 133.7 356.9V358.6C133.7 358.812 133.616 359.016 133.466 359.166C133.316 359.316 133.112 359.4 132.9 359.4H115.7L103.9 334.6Z" fill="white"/>
            <path d="M132.9 359.4C133.112 359.4 133.316 359.316 133.466 359.166C133.616 359.016 133.7 358.812 133.7 358.6V356.9C126.9 356.9 121.9 357.6 119.4 353.2C116.717 347.876 114.347 342.4 112.3 336.8C111.4 334.2 109.5 331.1 106.6 332L103.9 334.6L115.7 359.4H132.9Z" fill="#FF78B9"/>
            <path d="M125.6 359.4C123.791 359.428 122.005 358.992 120.412 358.134C118.819 357.277 117.473 356.026 116.5 354.5C112.8 348.9 110.5 342.2 109.1 338.1C107.7 334 103.2 332.4 102.8 337.6C102.4 342.8 105.1 346.8 109.3 357.2C109.532 357.858 109.967 358.425 110.542 358.821C111.117 359.216 111.802 359.419 112.5 359.4H125.6Z" fill="black"/>
            <path d="M91.5001 247.3L107.3 252.7C108.9 230.6 115.6 224.3 123.4 206.7C129 194.2 131.6 188.5 132.7 186.3C132.86 185.933 133.127 185.622 133.466 185.408C133.804 185.194 134.2 185.087 134.6 185.1H135.2C135.507 185.099 135.81 185.162 136.091 185.284C136.372 185.407 136.624 185.587 136.831 185.813C137.038 186.039 137.196 186.306 137.294 186.597C137.392 186.887 137.428 187.195 137.4 187.5C136.405 193.407 134.831 199.202 132.7 204.8C137.4 201.6 139.5 201.6 141.7 204.9C147.1 202.1 149.1 202 151 206.2C152.9 210.4 152.6 211.2 151.6 214.6C150.6 218 149.1 218.3 146.8 218.3C146.8 241.5 145.3 250.2 134.4 265.8C124.7 279.7 124.2 278 111 273.8C103.4 271.4 95.9001 268.7 91.0001 266.8C88.1608 265.766 85.7747 263.768 84.2579 261.155C82.741 258.541 82.1896 255.478 82.7 252.5C83.6 248.3 86.4001 245.6 91.5001 247.3Z" fill="#FFE75C"/>
            <path d="M91.5 247.3C92.0389 246.663 92.5397 245.996 93 245.3C93.2674 244.929 93.4523 244.505 93.5419 244.056C93.6316 243.608 93.6241 243.145 93.5199 242.7C93.4156 242.254 93.217 241.836 92.9376 241.474C92.6581 241.112 92.3044 240.814 91.9 240.6C64.6 225.6 55 223.8 40 223.8C30.3 223.8 30.7999 226.3 31.6999 230.9C24.7999 237.3 24.8999 239.5 29.6999 245.8C34.4999 252.1 36 254.6 40.5 254.6C45 254.6 55.4999 254.7 64.6999 250C73.8999 245.3 78.3 242.9 91.5 247.3Z" fill="white"/>
            <path d="M91.0999 202C79.9999 200.1 68.6999 198.9 68.5999 198.9C68.4868 198.886 68.3719 198.896 68.263 198.93C68.1541 198.963 68.0537 199.02 67.9685 199.096C67.8832 199.172 67.8152 199.265 67.7689 199.369C67.7226 199.473 67.699 199.586 67.6999 199.7C67.6696 199.924 67.7248 200.152 67.8546 200.337C67.9843 200.522 68.179 200.652 68.4 200.7C68.6 200.7 80.4 201.9 91.8 203.9C91.6 203.3 91.3999 202.7 91.0999 202Z" fill="black"/>
            <path d="M68.8 198.4C68.2061 199.263 67.6715 200.165 67.2 201.1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M107.3 252.7L113.9 254.6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M50.4 183.7C50.2976 184.077 50.1638 184.445 50 184.8C49.931 184.961 49.8209 185.102 49.6804 185.207C49.54 185.312 49.3742 185.379 49.2 185.4H48.9C48.7902 185.367 48.6886 185.312 48.6022 185.236C48.5158 185.161 48.4465 185.068 48.399 184.964C48.3516 184.86 48.3272 184.746 48.3274 184.632C48.3275 184.517 48.3523 184.404 48.4 184.3C48.5 184 50.6 177.8 52.5 173.4C52.5802 173.222 52.7224 173.08 52.9 173C52.9 174.2 53 175.3 53.1 176.5C53.2 177.7 51 181.9 50.4 183.7Z" fill="#FFC619"/>
            <path d="M143.8 248C144.159 246.937 144.265 245.805 144.108 244.693C143.952 243.582 143.538 242.523 142.9 241.6C142.456 241.077 141.953 240.607 141.4 240.2C140.156 239.492 138.738 239.145 137.307 239.198C135.877 239.251 134.489 239.702 133.3 240.5C133.9 237.1 134.7 230.9 134.1 226.2C133.5 221.5 131.7 222.1 130.6 221.9C127.4 221.3 123 224.7 119.1 230.8C118.4 227.5 117.4 225.9 115.8 225.5C115.363 225.412 114.909 225.473 114.511 225.672C114.112 225.871 113.792 226.198 113.6 226.6L112.1 230.2C114.1 227.2 115.2 227.2 115.5 227.2C115.8 227.2 117.1 228 117.8 233.4C117.821 233.578 117.902 233.744 118.029 233.871C118.156 233.998 118.322 234.079 118.5 234.1C118.687 234.136 118.881 234.117 119.058 234.046C119.236 233.975 119.389 233.855 119.5 233.7C123.6 226.2 128 223.2 130.3 223.6C132.6 224 132.2 225.3 132.3 226.5C133.2 232.6 131.2 242.3 131.2 242.4C131.172 242.573 131.195 242.75 131.266 242.91C131.337 243.069 131.453 243.205 131.6 243.3C131.776 243.382 131.97 243.416 132.163 243.398C132.357 243.381 132.542 243.312 132.7 243.2C134.9 240.9 138.3 240.3 140.4 241.7C142.5 243.1 143.5 248.2 141.2 254.5C142.254 252.413 143.125 250.238 143.8 248Z" fill="#FFF4B3"/>
            <path d="M127.2 207.1C130.6 205.2 131.8 204.1 134.8 207.1C138.8 205 139.6 205 141.8 208.2" stroke="#FFC619" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M148.6 217.9C148.5 215.1 148.2 210.8 148.1 210.7C148.101 210.586 148.077 210.473 148.031 210.369C147.985 210.265 147.917 210.172 147.831 210.096C147.746 210.02 147.646 209.963 147.537 209.93C147.428 209.896 147.313 209.886 147.2 209.9C147.086 209.899 146.973 209.923 146.869 209.969C146.765 210.015 146.672 210.083 146.596 210.169C146.52 210.254 146.463 210.354 146.43 210.463C146.396 210.572 146.386 210.687 146.4 210.8C146.4 210.9 146.8 215.2 146.8 218C146.795 218.054 146.802 218.109 146.819 218.16C146.836 218.212 146.864 218.259 146.9 218.3H148.5L148.6 217.9Z" fill="black"/>
            <path d="M149.7 214.5H147.5C147.382 214.5 147.265 214.477 147.156 214.432C147.046 214.386 146.947 214.32 146.864 214.236C146.78 214.153 146.714 214.054 146.668 213.944C146.623 213.835 146.6 213.718 146.6 213.6C146.625 213.379 146.73 213.175 146.896 213.027C147.063 212.88 147.278 212.799 147.5 212.8H149.7C149.922 212.799 150.137 212.88 150.303 213.027C150.47 213.175 150.575 213.379 150.6 213.6C150.6 213.718 150.577 213.835 150.531 213.944C150.486 214.054 150.42 214.153 150.336 214.236C150.253 214.32 150.154 214.386 150.044 214.432C149.935 214.477 149.818 214.5 149.7 214.5Z" fill="black"/>
            <path d="M118.5 272.4C120.611 272.348 122.717 272.148 124.8 271.8" stroke="#FFC619" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M54.5 294.2C43.1 292.8 38 290.7 37.9 290.7H37.8V292.5C39.5 293.1 44.6 294.7 54.3 296H54.4C54.6224 296.001 54.8374 295.92 55.0035 295.773C55.1697 295.625 55.2753 295.421 55.3 295.2C55.3139 295.082 55.3042 294.962 55.2714 294.847C55.2386 294.733 55.1833 294.626 55.1089 294.533C55.0345 294.44 54.9424 294.363 54.8379 294.305C54.7334 294.248 54.6185 294.212 54.5 294.2Z" fill="black"/>
            <path d="M96.9 289.6C94.3486 290.413 92.044 291.858 90.2 293.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M133.7 297.9C137.741 296.112 141.651 294.04 145.4 291.7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M135.7 297L141 297.2" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.6 230.4C49.2 228.8 61 232.6 77.3 239.9C61 238.8 41.8 238.2 41.8 238.2" stroke="#BAE8FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28.1 312.5H94.7C95.6547 312.5 96.5705 312.879 97.2456 313.554C97.9207 314.23 98.3 315.145 98.3 316.1V359.4H24.5V316.1C24.5 315.145 24.8793 314.23 25.5544 313.554C26.2296 312.879 27.1452 312.5 28.1 312.5Z" fill="#8CD9FF"/>
            <path d="M91.7 312.5H94.7C95.6547 312.5 96.5705 312.879 97.2456 313.554C97.9207 314.23 98.3 315.145 98.3 316.1V359.4H88.1V316.1C88.1 315.627 88.1931 315.159 88.374 314.722C88.5549 314.286 88.8201 313.889 89.1544 313.554C89.4887 313.22 89.8856 312.955 90.3224 312.774C90.7592 312.593 91.2272 312.5 91.7 312.5Z" fill="#45AAF7"/>
            <path d="M34.3 345.6H78.3C79.0957 345.6 79.8587 345.916 80.4213 346.479C80.9839 347.041 81.3 347.804 81.3 348.6V359.4H31.4V348.6C31.3996 347.821 31.7019 347.073 32.243 346.513C32.7842 345.953 33.5219 345.626 34.3 345.6Z" fill="#45AAF7"/>
            <path d="M36.3 312.5V359.4" stroke="#5865F2" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M76.2 312.5V359.4" stroke="#5865F2" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M78.7 316.1H33.8C32.4193 316.1 31.3 317.219 31.3 318.6V334.59C31.3 335.971 32.4193 337.09 33.8 337.09H78.7C80.0807 337.09 81.2 335.971 81.2 334.59V318.6C81.2 317.219 80.0807 316.1 78.7 316.1Z" fill="black"/>
            <path d="M87.1999 319.5C87.0647 319.484 86.9277 319.499 86.799 319.543C86.6704 319.588 86.5535 319.661 86.4572 319.757C86.3609 319.854 86.2878 319.97 86.2432 320.099C86.1987 320.228 86.1838 320.365 86.1999 320.5C86.1999 320.765 86.3053 321.02 86.4929 321.207C86.6804 321.395 86.9347 321.5 87.1999 321.5H98.3V319.5H87.1999Z" fill="#5865F2"/>
            <path d="M86.1999 324.8C86.1838 324.935 86.1987 325.072 86.2432 325.201C86.2878 325.33 86.3609 325.446 86.4572 325.543C86.5535 325.639 86.6704 325.712 86.799 325.757C86.9277 325.801 87.0647 325.816 87.1999 325.8H98.3V323.8H87.1999C86.9347 323.8 86.6804 323.905 86.4929 324.093C86.3053 324.28 86.1999 324.535 86.1999 324.8Z" fill="#5865F2"/>
            <path d="M86.1999 329.2C86.1999 329.465 86.3053 329.72 86.4929 329.907C86.6804 330.095 86.9347 330.2 87.1999 330.2H98.3V328.2H87.1999C87.0647 328.184 86.9277 328.199 86.799 328.243C86.6704 328.288 86.5535 328.361 86.4572 328.457C86.3609 328.554 86.2878 328.671 86.2432 328.799C86.1987 328.928 86.1838 329.065 86.1999 329.2Z" fill="#5865F2"/>
            <path d="M26.3 320.5C26.2775 320.758 26.1649 320.999 25.9821 321.182C25.7992 321.365 25.5577 321.477 25.3 321.5H24.5V319.5H25.3C25.5653 319.5 25.8195 319.605 26.0071 319.793C26.1946 319.98 26.3 320.235 26.3 320.5Z" fill="#5865F2"/>
            <path d="M26.3 324.8C26.3 325.065 26.1946 325.32 26.0071 325.507C25.8195 325.695 25.5653 325.8 25.3 325.8H24.5V323.8H25.3C25.5577 323.823 25.7992 323.935 25.9821 324.118C26.1649 324.301 26.2775 324.542 26.3 324.8Z" fill="#5865F2"/>
            <path d="M26.3 329.2C26.2775 329.458 26.1649 329.699 25.9821 329.882C25.7992 330.065 25.5577 330.178 25.3 330.2H24.5V328.2H25.3C25.5653 328.2 25.8195 328.305 26.0071 328.493C26.1946 328.68 26.3 328.935 26.3 329.2Z" fill="#5865F2"/>
            <path d="M65.9 317.6H36.6C35.882 317.6 35.3 318.182 35.3 318.9V332.75C35.3 333.468 35.882 334.05 36.6 334.05H65.9C66.618 334.05 67.2 333.468 67.2 332.75V318.9C67.2 318.182 66.618 317.6 65.9 317.6Z" fill="#FFF4B3"/>
            <path d="M78.3 334.1H70.3C69.803 334.1 69.4 334.503 69.4 335V336.22C69.4 336.717 69.803 337.12 70.3 337.12H78.3C78.7971 337.12 79.2 336.717 79.2 336.22V335C79.2 334.503 78.7971 334.1 78.3 334.1Z" fill="#FF78B9"/>
            <path d="M47.1 319.5C47.1 321.94 48.0694 324.28 49.7947 326.005C51.52 327.731 53.8601 328.7 56.3001 328.7C58.7401 328.7 61.0801 327.731 62.8054 326.005C64.5308 324.28 65.5 321.94 65.5 319.5C65.4881 318.862 65.4212 318.226 65.3001 317.6H47.3001C47.1288 318.218 47.0612 318.86 47.1 319.5Z" fill="#29CC7A"/>
            <path d="M38 334.1H53C52.1496 332.903 51.025 331.927 49.7202 331.253C48.4154 330.58 46.9684 330.228 45.5 330.228C44.0316 330.228 42.5846 330.58 41.2798 331.253C39.975 331.927 38.8504 332.903 38 334.1Z" fill="#29CC7A"/>
        </svg>

     );
}

export default LandingGraphicRight;
