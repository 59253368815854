function LandingGraphicLeft({ className }) {
    return ( 
        <svg className={className} width="689" height="352" viewBox="0 0 689 352" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M291.399 351.9H18.0994C6.79941 341.8 -0.100599 330.1 0.999401 319.3C3.2994 296 39.9994 292.6 75.5994 309.4L82.5994 312.9C83.7793 308.614 85.6724 304.557 88.1994 300.9C105.999 274.8 146.199 272.3 172.199 291.4C175.065 293.495 177.742 295.838 180.199 298.4C183.695 293.567 187.683 289.109 192.099 285.1C216.199 263.3 244.799 258.6 255.699 272C265.299 283.8 257.899 306.5 239.699 324.4C239.699 324.4 293.499 325.7 291.399 351.9Z" fill="#404EED"/>
            <path d="M629.4 351.4C607.3 305.8 594.9 251.2 594.9 251.2L593.9 245.9L549.4 251.2V351.4H629.4Z" fill="#2835BD"/>
            <path d="M619.8 351.4C600.4 311.4 589.6 263.5 589.6 263.5L587 250.1L549.7 263.5V351.4H619.8Z" fill="black"/>
            <path d="M626.8 218H610.6V351.46H626.8V218Z" fill="#2835BD"/>
            <path d="M626.8 235.3C624.374 236.405 621.683 236.794 619.044 236.422C616.404 236.05 613.926 234.932 611.9 233.2L610.6 232.1V226.1L626.8 223V235.3Z" fill="black"/>
            <path d="M602 351.4C613.1 348.7 628.2 334 628.2 334C628.2 334 633.5 345.4 636.6 351.4H647.8C649.009 351.407 650.206 351.158 651.313 350.671C652.42 350.184 653.411 349.468 654.223 348.572C655.034 347.675 655.648 346.618 656.023 345.468C656.398 344.319 656.527 343.103 656.4 341.9C655.9 337 653.6 331.5 650.1 323.5C644.2 310.2 642.3 303.2 630.8 303.2H620.4L596.4 292.6C581 300.7 570.4 305.4 561.3 310.8C547.9 319 537.3 335.2 547.7 351.4H602Z" fill="#FFC619"/>
            <path d="M433 263.5V351.4H554.8C574.2 311.4 585 263.5 585 263.5L587.9 251.2L433 263.5Z" fill="#2835BD"/>
            <path d="M557.2 266.2L433 277.8V351.4H525.9C534.9 330.9 557.2 266.2 557.2 266.2Z" fill="black"/>
            <path d="M540.7 344.7C550.945 328.858 559.08 311.747 564.9 293.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M526.2 332.5C521.055 332.5 516.12 334.544 512.482 338.182C508.844 341.82 506.8 346.755 506.8 351.9H545.6C545.6 346.755 543.556 341.82 539.918 338.182C536.28 334.544 531.345 332.5 526.2 332.5Z" fill="#404EED"/>
            <path d="M553.8 351.4C573.2 311.4 584.1 263.5 584.1 263.5L553.8 351.4Z" fill="#2835BD"/>
            <path d="M591.3 351.3C591.2 351.1 584.3 333.7 580.7 322.5L580.5 321.8C578.1 314.2 576.1 308.1 576.4 302.7L574.7 303.6C574.7 309 576.6 315 579 322.3L579.2 323C582.5 333.2 588.4 348.5 589.6 351.4H591.3V351.3Z" fill="black"/>
            <path d="M653.8 349C654.417 348.397 654.883 347.657 655.161 346.841C655.438 346.024 655.521 345.154 655.4 344.3C654.7 337.2 651 328.7 647.1 319.9L643.1 310.5L641.6 311.1C642.9 314.3 644.3 317.4 645.6 320.5C650.3 331.2 654.8 341.5 653.8 349Z" fill="white"/>
            <path d="M620.4 322.9C618.5 330.7 615.8 340.4 612.3 351.4H591.3C591.3 351.4 584.5 333.9 580.8 322.6C577.1 311.3 574.4 303.2 578.1 295.7C581.8 288.2 587.6 287.6 596.4 292.6L595.1 306.8L601.8 325.8L604.9 315.7C611.5 311.4 619.8 313.8 620.4 322.9Z" fill="#FFC619"/>
            <path d="M593.3 345.1L591.6 340.2C587.9 329.3 584.7 320 582.5 311.5C580.7 304.5 579.2 296.2 580.1 292.5C579.365 293.396 578.728 294.368 578.2 295.4C578.2 300.8 580 308.3 580.9 311.9C583.2 320.5 586.4 329.8 590.1 340.7L591.8 345.7C592.533 347.767 593.233 349.667 593.9 351.4H595.7C594.9 349.6 594.2 347.6 593.3 345.1Z" fill="white"/>
            <path d="M608.5 351.4C610.8 343.7 614.1 332.4 618.4 317.8C618.43 317.695 618.438 317.585 618.423 317.477C618.409 317.368 618.373 317.264 618.316 317.17C618.26 317.077 618.185 316.995 618.096 316.932C618.008 316.868 617.907 316.823 617.8 316.8C617.703 316.752 617.596 316.727 617.488 316.727C617.379 316.727 617.272 316.752 617.175 316.801C617.078 316.849 616.994 316.92 616.929 317.006C616.864 317.093 616.82 317.194 616.8 317.3C612.4 332.2 609.1 343.7 606.8 351.4H608.5Z" fill="white"/>
            <path d="M616.6 261.4C604 259.6 599.2 260.5 594.5 271.1C589.8 281.7 592.2 290.7 600 302.1C607.8 313.5 627 311.4 634.3 308.7C641.6 306 648 298.7 651.6 288.4C655.2 278.1 654.1 266.8 648.7 260.6C643.3 254.4 634.2 249.3 626 251.9C617.8 254.5 616.6 261.4 616.6 261.4Z" fill="#DE3A93"/>
            <path d="M622.7 276.3L618.9 275.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M622.9 277.9L619 278.4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M650.5 276L653.5 274" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M650.3 277.7L653.4 277.2" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M612.5 251.2C610.4 251.2 608.8 252.6 608.7 253.6C608.6 254.6 612.4 257.8 617.3 263.9L618.6 263.4C614 251.6 614.6 251.1 612.5 251.2Z" fill="black"/>
            <path d="M622.2 260.5C618.4 262.9 614 266.2 614 266.2" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M620.4 322.9C621.6 317.8 622.5 313.5 623 310.2C624.4 301.6 621.8 300.2 618 299.1C614.2 298 611.5 295.9 609.7 289.4C607.9 282.9 606.8 283 604.6 284.1C602.2 285.399 600.143 287.25 598.6 289.5C597.4 291.6 597.6 294 599.9 299.9C601.422 303.59 603.867 306.827 607 309.3L604.9 315.7C612.6 315.4 617.2 316.4 620.4 322.9Z" fill="#FF78B9"/>
            <path d="M632.9 318.9C631.8 314 630.4 308.7 632.9 307.3C635.4 305.9 643.1 302.3 647.3 293.2C647.388 292.974 647.52 292.767 647.69 292.593C647.859 292.419 648.062 292.281 648.287 292.188C648.511 292.094 648.752 292.047 648.995 292.049C649.237 292.052 649.477 292.103 649.7 292.2H649.9C650.082 292.266 650.249 292.368 650.39 292.502C650.531 292.635 650.642 292.796 650.717 292.974C650.792 293.153 650.83 293.345 650.827 293.539C650.824 293.733 650.781 293.924 650.7 294.1C648.654 298.45 646.211 302.601 643.4 306.5C643.983 308.845 644.718 311.15 645.6 313.4C641 315.7 632.9 318.9 632.9 318.9Z" fill="#FF78B9"/>
            <path d="M608.3 302.9C608.448 302.735 608.529 302.522 608.529 302.3C608.529 302.079 608.448 301.865 608.3 301.7C606.805 300.133 605.466 298.425 604.3 296.6C602.4 293.3 600.6 287.4 601.9 285.9L600.5 287.2C600.278 287.44 600.109 287.725 600.006 288.035C599.902 288.346 599.866 288.675 599.9 289C600.429 291.947 601.443 294.785 602.9 297.4C604.109 299.372 605.517 301.214 607.1 302.9L607.7 303.1L608.3 302.9Z" fill="black"/>
            <path d="M607.8 309.5C610.685 309.144 613.594 309.011 616.5 309.1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M598.3 336.7L604.6 316.7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M601.8 325.8L595.7 308.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M633 319.7C634.8 329.4 638.7 343 638.7 343" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M551.9 326C548.426 326 545.094 327.38 542.637 329.837C540.18 332.294 538.8 335.626 538.8 339.1V351.9H564.9V339.1C564.9 335.643 563.534 332.326 561.099 329.872C558.663 327.418 555.357 326.027 551.9 326Z" fill="#404EED"/>
            <path d="M564.9 339.1C563.211 339.087 561.535 339.408 559.971 340.046C558.406 340.683 556.984 341.624 555.784 342.814C554.585 344.004 553.633 345.419 552.984 346.979C552.334 348.538 552 350.211 552 351.9H577.7C577.7 348.505 576.351 345.25 573.951 342.849C571.551 340.449 568.295 339.1 564.9 339.1Z" fill="#404EED"/>
            <path d="M605 227.5L624.7 224.4" stroke="black" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M646.2 264.1L646.9 265.2" stroke="#FFA8D2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M636.3 256.8C638.889 257.576 641.278 258.907 643.3 260.7" stroke="#FFA8D2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M624.2 224.5C640.9 221.7 667.7 215.5 681.6 211.8" stroke="#2835BD" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M676.6 214.5L681.9 239.1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M686.9 238.3V235.1H666.3V238.4C666.258 238.857 666.122 239.299 665.9 239.7C664.868 241.202 664.311 242.978 664.3 244.8V257.9C664.302 260.114 665.102 262.252 666.553 263.924C668.004 265.595 670.009 266.687 672.2 267C675.153 267.4 678.147 267.4 681.1 267C683.264 266.663 685.233 265.558 686.647 263.886C688.062 262.215 688.826 260.09 688.8 257.9V244.8C688.825 242.988 688.302 241.21 687.3 239.7C687.019 239.289 686.878 238.798 686.9 238.3Z" fill="#8CD9FF"/>
            <path d="M685 239.7V235.6H668.1V239.7C668.105 240.506 667.898 241.299 667.5 242C666.702 243.18 666.284 244.576 666.3 246V257.2C666.302 259.06 666.968 260.857 668.178 262.269C669.388 263.681 671.063 264.614 672.9 264.9C675.291 265.2 677.709 265.2 680.1 264.9C681.964 264.656 683.675 263.738 684.909 262.32C686.143 260.901 686.816 259.08 686.8 257.2V246C686.791 244.593 686.412 243.214 685.7 242C685.275 241.305 685.034 240.514 685 239.7Z" fill="#5865F2"/>
            <path d="M666.3 236.7C666.3 238.1 670.9 239.2 676.6 239.2C682.3 239.2 686.9 238.1 686.9 236.7L676.6 232.6L666.3 236.7Z" fill="#8CD9FF"/>
            <path d="M665.7 235.9C665.612 236.05 665.555 236.217 665.532 236.39C665.51 236.563 665.522 236.738 665.569 236.906C665.615 237.074 665.695 237.231 665.804 237.367C665.912 237.504 666.047 237.617 666.2 237.7C667.9 238.7 671.9 239.4 676.6 239.4C681.3 239.4 685.3 238.7 687 237.7C687.29 237.514 687.502 237.228 687.594 236.896C687.686 236.564 687.653 236.209 687.5 235.9L676.6 214.5L665.7 235.9Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M675.6 258L672.5 257.3C672.201 257.192 671.952 256.979 671.8 256.7C671.658 256.421 671.631 256.097 671.724 255.798C671.818 255.499 672.024 255.249 672.3 255.1C672.45 255.012 672.617 254.955 672.79 254.932C672.962 254.91 673.138 254.922 673.306 254.969C673.474 255.015 673.631 255.095 673.767 255.204C673.903 255.312 674.017 255.447 674.1 255.6L675.6 258Z" fill="white"/>
            <path d="M675.6 258L675.4 254.9C675.394 254.753 675.418 254.607 675.469 254.47C675.521 254.332 675.599 254.207 675.7 254.1C675.785 253.97 675.9 253.861 676.034 253.783C676.169 253.704 676.32 253.659 676.475 253.649C676.63 253.639 676.786 253.666 676.929 253.727C677.072 253.788 677.199 253.881 677.3 254C677.561 254.215 677.728 254.523 677.765 254.86C677.802 255.196 677.707 255.533 677.5 255.8L675.6 258Z" fill="white"/>
            <path d="M676.2 260.9C674.5 261.4 672.9 260.9 672.5 259.8C672.1 258.7 672.7 258.1 674.3 257.7C674.998 257.52 675.67 257.251 676.3 256.9C677.6 256.2 678.3 257 678.6 257.9C678.9 258.8 677.9 260.3 676.2 260.9Z" fill="#121B80"/>
            <path d="M673.136 257.878L671.936 257.857C671.108 257.843 670.424 258.502 670.41 259.331L670.408 259.421C670.394 260.249 671.054 260.932 671.882 260.947L673.082 260.968C673.91 260.982 674.593 260.322 674.608 259.494L674.609 259.404C674.624 258.576 673.964 257.893 673.136 257.878Z" fill="#FFE75C"/>
            <path d="M678.8 246.2L681.6 244.7C681.821 244.511 681.992 244.27 682.1 244C682.146 243.849 682.162 243.69 682.147 243.533C682.132 243.376 682.086 243.223 682.011 243.084C681.937 242.945 681.836 242.821 681.714 242.721C681.592 242.621 681.451 242.546 681.3 242.5C680.968 242.408 680.614 242.451 680.314 242.619C680.014 242.788 679.793 243.069 679.7 243.4L678.8 246.2Z" fill="white"/>
            <path d="M678.8 246.2L678.3 243.1C678.193 242.8 678.023 242.527 677.8 242.3C677.524 242.153 677.206 242.103 676.898 242.157C676.59 242.211 676.309 242.367 676.1 242.6C675.908 242.907 675.843 243.276 675.917 243.631C675.992 243.985 676.201 244.297 676.5 244.5L678.8 246.2Z" fill="white"/>
            <path d="M679.6 245.8C681 246.8 681.7 248.4 681 249.3C680.3 250.2 679.5 250.3 678.2 249.2C677.653 248.754 677.047 248.384 676.4 248.1C676.153 248.039 675.928 247.912 675.747 247.732C675.567 247.553 675.44 247.327 675.378 247.081C675.316 246.834 675.323 246.575 675.397 246.332C675.472 246.089 675.611 245.87 675.8 245.7C676.4 244.7 678.2 244.8 679.6 245.8Z" fill="#121B80"/>
            <path d="M678.905 249.398L679.612 250.368C680.1 251.037 681.038 251.184 681.708 250.696L681.78 250.643C682.45 250.155 682.597 249.216 682.109 248.547L681.402 247.577C680.913 246.908 679.975 246.761 679.306 247.249L679.233 247.302C678.564 247.79 678.417 248.729 678.905 249.398Z" fill="#FFE75C"/>
            <path d="M676.6 237.6C682.289 237.6 686.9 236.481 686.9 235.1C686.9 233.719 682.289 232.6 676.6 232.6C670.911 232.6 666.3 233.719 666.3 235.1C666.3 236.481 670.911 237.6 676.6 237.6Z" fill="#8CD9FF"/>
            <path d="M687 235.1H666.3V236.52H687V235.1Z" fill="#8CD9FF"/>
            <path d="M680.8 235.9C680.3 236 679.9 236.3 679.9 236.8C679.912 236.906 679.946 237.007 679.998 237.1C680.05 237.192 680.12 237.273 680.203 237.339C680.287 237.404 680.383 237.452 680.485 237.479C680.588 237.507 680.695 237.514 680.8 237.5L682.5 237.2C682.725 237.162 682.932 237.055 683.093 236.894C683.255 236.732 683.362 236.525 683.4 236.3C683.39 236.197 683.357 236.098 683.303 236.009C683.25 235.921 683.178 235.845 683.092 235.787C683.006 235.73 682.908 235.693 682.806 235.677C682.703 235.662 682.599 235.67 682.5 235.7L680.8 235.9Z" fill="white"/>
            <path d="M676.6 214.5L671.3 239.1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M677.4 216.2L674.8 210.6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M676.8 216.5L677.1 210.1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M648.7 281.4C652 277.6 651.5 275.3 650.4 274.6C649.3 273.9 647.6 275.2 644.6 280C641.6 284.8 644.1 286.6 648.7 281.4Z" fill="white"/>
            <path d="M633 281.4C630.8 276.8 627 273.5 623.5 274C620 274.5 621.4 279.7 626 283.2C630.6 286.7 635.4 286.4 633 281.4Z" fill="white"/>
            <path d="M650.2 277.2C650.394 276.3 650.324 275.362 650 274.5C649.1 274.4 647.7 275.4 645.8 278.1C646 279.5 646.7 280.5 647.5 280.6C648.3 280.7 650 279.3 650.2 277.2Z" fill="black"/>
            <path d="M633.1 281.7C633.1 281.6 633 281.5 633 281.4C631.933 279.083 630.32 277.058 628.3 275.5C627.723 276.643 627.48 277.925 627.6 279.2C627.8 281.9 629.2 284 630.8 283.9C631.367 283.824 631.895 283.57 632.309 283.175C632.722 282.78 632.999 282.263 633.1 281.7Z" fill="black"/>
            <path d="M635 291.7C641.3 290.1 643.8 289.4 644.8 290.8C645.8 292.2 644.9 293.8 643.4 295.4C645 298.9 644.5 300.5 641.4 300.5C639.14 300.339 636.949 299.654 635 298.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M641.7 293.7C641.308 294.738 640.769 295.715 640.1 296.6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M213.6 351.4H224L231.8 327.6H211.3L213.6 351.4Z" fill="black"/>
            <path d="M344.7 351.4H289C253.2 351.4 232.6 341.1 217.2 329.4L213.6 351.4H204.5C204.5 245.8 218.1 145.6 218.1 145.6C220.1 134.3 241.6 129.2 268.3 138.5C295 147.8 302.1 159.7 301.1 170.5C300.1 181.3 291.6 254.1 288.4 288.2L294.1 294.4C296.066 296.443 297.524 298.919 298.358 301.628C299.192 304.338 299.378 307.205 298.9 310L298.3 313.3C320.3 327.5 354.3 320.4 344.7 351.4Z" fill="#29CC7A"/>
            <path d="M229.2 165C228.3 177.6 228.1 182.8 228.1 184.8C228.093 185.116 228.152 185.43 228.273 185.723C228.393 186.015 228.573 186.28 228.8 186.5C234.7 192.4 241.7 194.3 247.2 195C247.518 195.042 247.841 195.017 248.149 194.927C248.457 194.836 248.742 194.683 248.987 194.476C249.232 194.268 249.431 194.012 249.571 193.724C249.711 193.435 249.789 193.121 249.8 192.8L250.9 176.8" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M224.8 282.3C221.487 282.37 218.214 281.578 215.3 280" stroke="black" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M216.5 286.9C220.8 287.5 226.1 285.6 226.1 285.6" stroke="black" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M275.8 215.1C274.9 224.1 274.7 234 279.2 236.6C282 238.3 287.7 238.1 294 235.1C296.5 210.2 299.2 188.8 300.5 176.7L270.7 181.6C267.8 207.3 267.3 215.7 275.8 215.1Z" fill="#15AD75"/>
            <path d="M296.531 178.681C301.31 168.661 288.495 152.579 267.907 142.759C247.32 132.939 226.756 133.101 221.977 143.121C217.198 153.14 230.013 169.223 250.601 179.043C271.188 188.862 291.752 188.7 296.531 178.681Z" fill="black"/>
            <path d="M217.2 329.4C232.6 341.1 253.2 351.4 289 351.4H344.7C345.648 348.523 346.187 345.527 346.3 342.5C287.2 342.5 252.9 341.3 205.3 305.7C204.9 319.9 204.6 335.2 204.5 351.4H213.6L217.2 329.4Z" fill="#57F287"/>
            <path d="M221.2 279.1C223.8 289.2 230.9 296.9 237.1 296.3C243.3 295.7 243.5 289.9 241.1 280.2C240.5 277.6 240 275.1 239.5 272.5C237.7 263.3 233.2 259.3 227.7 259.8C222.2 260.3 218.5 269.1 221.2 279.1Z" fill="white"/>
            <path d="M221.2 279.1C223.8 289.2 230.9 296.9 237.1 296.3C243.3 295.7 243.5 289.9 241.1 280.2C240.5 277.6 240 275.1 239.5 272.5C237.7 263.3 233.2 259.3 227.7 259.8C222.2 260.3 218.5 269.1 221.2 279.1Z" fill="white"/>
            <path d="M237.5 279.2C238.5 283.6 237.8 287.5 236.1 287.8C234.4 288.1 232.3 285.8 231.6 280.3C230.9 274.8 231.3 271.8 233 271.8C234.7 271.8 236.6 274.7 237.5 279.2Z" fill="black"/>
            <path d="M295.4 312.5C295.548 312.509 295.696 312.48 295.831 312.418C295.966 312.355 296.083 312.26 296.172 312.141C296.261 312.023 296.319 311.884 296.342 311.737C296.364 311.59 296.35 311.44 296.3 311.3C294.3 301.3 288.2 299.9 276.9 302.5C265.6 305.1 261.1 306.1 258.9 306.9C256.7 307.7 257.7 308.2 272.6 310.5C280.135 311.767 287.759 312.436 295.4 312.5Z" fill="#15AD75"/>
            <path d="M345.8 337.4C334.7 337.5 316.2 337.3 298 336.1C264.2 333.8 233.1 321.2 205.5 298.7V300.5C233.1 322.8 264.1 335.3 297.9 337.5C314.9 338.7 332.1 338.9 343.4 338.9H346.1L345.8 337.4Z" fill="black"/>
            <path d="M239.5 272.6C237.7 263.4 233.2 259.4 227.7 259.9C222.2 260.4 220.7 264.5 220.3 270.4C226.491 272.398 233.017 273.146 239.5 272.6Z" fill="#5865F2"/>
            <path d="M381.4 192.3L348.1 210.9C348.67 211.427 349.387 211.767 350.156 211.875C350.925 211.982 351.708 211.851 352.4 211.5C354.3 210.7 358.9 206.5 360.7 205.5C362.5 204.5 367.6 202.5 370.1 201.5C373.183 200.279 376.078 198.629 378.7 196.6C380.9 194.8 381.7 194.1 381.4 192.3Z" fill="black"/>
            <path d="M299.2 123.2C293.1 119.4 292.8 119.5 289.4 113.4C286 107.3 281.7 104.3 276.7 103.6L282.9 148.5C295.9 152.3 303.9 145.7 305.7 139.2C307.5 132.7 305.4 127 299.2 123.2Z" fill="#3442D9"/>
            <path d="M293.1 129.1C291.4 121.8 286.9 113.3 281.6 106.9C279.701 104.588 278.354 101.873 277.661 98.9618C276.968 96.0509 276.947 93.0203 277.6 90.1001L279.8 80.1001C281.347 73.1595 280.522 65.9009 277.459 59.4839C274.395 53.0668 269.269 47.8617 262.9 44.7001C259.245 42.9006 255.267 41.8468 251.2 41.6001C243.547 41.0395 235.977 43.483 230.096 48.4118C224.215 53.3407 220.486 60.3671 219.7 68.0001C219.534 69.2931 219.467 70.5969 219.5 71.9001V76.2001L219.7 94.2001C219.917 102.287 218.203 110.308 214.7 117.6C213.246 120.493 212.139 123.548 211.4 126.7L260.3 178.7C283.6 174.8 298.2 152.3 293.1 129.1Z" fill="#3442D9"/>
            <path d="M260.4 178.7C283.6 174.8 298.2 152.3 293.1 129.1C291.5 122.2 287.4 114.2 282.5 108" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M277.5 130.6C282.1 123 283.9 124 292.1 126.2C298.7 127.9 306.3 141.4 296.2 148.2C286.1 155 265.5 150.9 277.5 130.6Z" fill="#3442D9"/>
            <path d="M254.703 108.187C255.625 107.482 255.773 106.129 255.035 105.163C254.297 104.198 252.952 103.987 252.031 104.691C251.11 105.396 250.961 106.749 251.699 107.714C252.437 108.68 253.782 108.891 254.703 108.187Z" fill="black"/>
            <path d="M267.299 108.969C268.22 108.265 268.369 106.911 267.631 105.946C266.893 104.981 265.548 104.769 264.626 105.474C263.705 106.178 263.556 107.532 264.294 108.497C265.032 109.462 266.377 109.674 267.299 108.969Z" fill="black"/>
            <path d="M266 107.4C276.7 116.2 272.3 122.5 280.9 135.5" stroke="white" strokeWidth="3.00593" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M253.9 127.1C260.5 122.5 262.5 121.2 265 121.3L271.5 121.9L269.8 148.3L253.3 147.8L253.9 127.1Z" fill="#FFC619"/>
            <path d="M285.9 120.4C286.025 120.051 286.066 119.677 286.022 119.309C285.978 118.941 285.849 118.588 285.646 118.278C285.442 117.968 285.17 117.709 284.85 117.522C284.529 117.335 284.17 117.225 283.8 117.2L272.8 115.6C272.002 115.515 271.198 115.707 270.525 116.144C269.851 116.581 269.348 117.236 269.1 118L262.9 141C262.776 141.35 262.734 141.723 262.778 142.091C262.822 142.459 262.951 142.812 263.154 143.122C263.358 143.432 263.63 143.691 263.951 143.878C264.271 144.065 264.63 144.175 265 144.2L276 145.8C276.799 145.885 277.602 145.693 278.276 145.256C278.949 144.819 279.452 144.164 279.7 143.4L285.9 120.4Z" fill="black"/>
            <path d="M285.4 118.1L286.6 119.5L283.3 119.8L285.4 118.1Z" fill="black"/>
            <path d="M290.8 133.3C289.4 131 288.1 128.1 284.3 127.5L283.8 132.3L290.8 133.3Z" fill="#FFC619"/>
            <path d="M287 121.7C287.105 121.351 287.132 120.983 287.079 120.622C287.026 120.261 286.895 119.916 286.695 119.611C286.495 119.306 286.231 119.049 285.921 118.857C285.611 118.665 285.262 118.543 284.9 118.5L273.8 116.9C273.005 116.833 272.209 117.033 271.54 117.467C270.87 117.902 270.363 118.546 270.1 119.3L263.9 142.3C263.795 142.649 263.768 143.017 263.821 143.378C263.874 143.739 264.005 144.084 264.205 144.389C264.405 144.694 264.669 144.952 264.979 145.143C265.29 145.335 265.638 145.457 266 145.5L277.1 147C277.878 147.128 278.676 146.963 279.34 146.539C280.004 146.114 280.489 145.46 280.7 144.7L287 121.7Z" fill="white"/>
            <path d="M283.6 130.8L288.1 131.5C289.012 131.601 289.847 132.056 290.427 132.766C291.007 133.477 291.284 134.387 291.2 135.3C291.177 135.775 291.055 136.24 290.842 136.665C290.628 137.089 290.328 137.465 289.961 137.766C289.593 138.068 289.167 138.289 288.708 138.415C288.25 138.542 287.77 138.571 287.3 138.5L284 138C282.975 137.853 282.027 137.371 281.303 136.63C280.58 135.888 280.122 134.929 280 133.9C279.975 133.442 280.056 132.984 280.236 132.562C280.416 132.14 280.691 131.765 281.038 131.465C281.386 131.166 281.798 130.95 282.242 130.835C282.686 130.72 283.151 130.708 283.6 130.8Z" fill="#FFC619"/>
            <path d="M282.1 137.5L285.2 138C285.719 138.066 286.217 138.243 286.66 138.52C287.103 138.797 287.48 139.168 287.765 139.605C288.051 140.043 288.238 140.538 288.313 141.055C288.388 141.572 288.349 142.099 288.2 142.6C287.987 143.406 287.477 144.102 286.772 144.547C286.067 144.992 285.22 145.154 284.4 145L282.5 144.7C281.463 144.557 280.501 144.079 279.761 143.339C279.021 142.599 278.543 141.637 278.4 140.6C278.371 140.131 278.453 139.663 278.638 139.231C278.824 138.8 279.108 138.418 279.468 138.116C279.828 137.815 280.253 137.602 280.711 137.495C281.168 137.388 281.644 137.39 282.1 137.5Z" fill="#FFC619"/>
            <path d="M272.4 148.9L261.6 147.4L262.1 143L272.9 144.6L272.4 148.9Z" fill="#FFC619"/>
            <path d="M275 131.4L268.2 130.4C267.876 130.356 267.572 130.219 267.323 130.007C267.075 129.794 266.893 129.514 266.8 129.2L266.2 127.4L265.7 123.7L262 136.7L273 138.3C274.024 138.437 275.064 138.225 275.953 137.699C276.842 137.173 277.528 136.364 277.9 135.4C278.019 134.943 278.035 134.465 277.948 134.001C277.86 133.537 277.671 133.098 277.394 132.716C277.117 132.333 276.758 132.017 276.345 131.79C275.931 131.562 275.472 131.429 275 131.4Z" fill="#FFC619"/>
            <path d="M272.8 138.2L262 136.7L262.5 143.9L270.8 145.1C271.813 145.251 272.846 145.043 273.722 144.514C274.598 143.985 275.262 143.166 275.6 142.2C275.737 141.75 275.77 141.275 275.697 140.81C275.623 140.345 275.444 139.904 275.175 139.518C274.905 139.133 274.551 138.814 274.139 138.585C273.728 138.357 273.27 138.225 272.8 138.2Z" fill="#FFC619"/>
            <path d="M349.4 182.3C349.7 171.5 340.8 169.8 334.7 166C328.6 162.2 321.4 154.2 313.9 148.8C306.4 143.4 299.9 143.5 287.2 147.4L301.7 175.6C309.9 179.8 311.7 184.4 316.1 190.6C320.5 196.8 326.4 199.2 332.3 198.1C335.4 203.2 336 204.4 340.5 202.8C345 201.2 352.4 197.8 354.4 191.9C356.4 186 353.5 185 349.4 182.3Z" fill="#66BCFF"/>
            <path d="M341.9 199.4C338.1 198.5 336.6 196.4 334.2 193.1L332.1 190.3C321.8 188.2 317.6 182.7 315.3 178.7C314.2 176.8 312.2 175.3 310.3 173.9C308.4 172.5 306.5 171 305.4 169.2C304.572 167.852 303.837 166.449 303.2 165C302.4 162.9 301.7 161.5 300.4 161.1C299.1 160.7 296.9 161.5 294.8 162.4C292.893 163.319 290.877 163.991 288.8 164.4C288.628 164.432 288.451 164.398 288.303 164.305C288.155 164.213 288.047 164.068 288 163.9C287.994 163.719 288.051 163.54 288.16 163.395C288.269 163.249 288.424 163.146 288.6 163.1C290.557 162.771 292.447 162.13 294.2 161.2C296.6 160.2 298.6 159.3 300.7 159.9C302.8 160.5 303.5 162.3 304.4 164.5C305.3 166.7 305.6 167.1 306.4 168.5C307.723 170.179 309.311 171.631 311.1 172.8C313.1 174.4 315.2 176 316.4 178.1C318.7 182.2 322.7 187.1 332.6 189.1H332.8L333 189.3C333.9 190.4 334.6 191.5 335.3 192.4C337.5 195.5 338.9 197.4 342.1 198.2C342.187 198.193 342.273 198.208 342.352 198.244C342.431 198.281 342.499 198.337 342.549 198.408C342.6 198.478 342.631 198.561 342.64 198.647C342.649 198.734 342.635 198.821 342.6 198.9C342.607 198.987 342.592 199.074 342.556 199.152C342.519 199.231 342.463 199.299 342.393 199.35C342.322 199.4 342.239 199.431 342.153 199.44C342.067 199.449 341.98 199.435 341.9 199.4Z" fill="#45AAF7"/>
            <path d="M301.1 159C299.756 163.293 298.885 167.719 298.5 172.2" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M307 211C297.9 219.2 290.2 216.9 286.6 216.5C283 216.1 283.4 213.5 283.1 211.3C282.8 209.1 282.6 205.8 282.6 205.8C273.5 205.2 272.4 199 272.2 193.5C272 188 270.6 187.2 269.9 183.3C258.928 181.329 248.558 176.859 239.592 170.234C230.626 163.61 223.307 155.009 218.2 145.1L227.7 138.2L248.9 152C263.2 146.7 271.1 144 278.8 143.5C286.5 143 294.1 145.9 296.6 156.6C299.1 167.3 299.4 170.6 301.7 175.7C302.3 177 303.3 178.8 304.3 180.7C307.1 186.4 310 194.4 304.3 203.1C304.995 205.799 305.898 208.441 307 211Z" fill="#8CD9FF"/>
            <path d="M233.7 165.3C233.4 158.2 236.1 153.2 241 152.4L228.6 143.8L219.6 147.7C221.9 154.1 227.8 161.1 233.7 165.3Z" fill="black"/>
            <path d="M289.6 212.9L289.8 211.6C288 211.3 287.9 210 287.4 203.9V203.1L287 202.9C283 201.9 280.3 199.5 280.1 191.4C280.066 187.553 279.285 183.749 277.8 180.2C277.412 179.367 277.11 178.495 276.9 177.6C276.7 176.8 276.4 176 276.2 175.3C275.071 172.477 274.759 169.393 275.3 166.4C275.7 163.3 275.4 161.6 274.4 160.7C273.4 159.8 270.9 160.1 267.2 161.3H266.7C260.8 163.2 249 167.1 237.1 168.1L238.6 169.2C250.2 168.1 261.4 164.4 267.1 162.6L267.6 162.4C270.6 161.4 272.7 160.9 273.6 161.6C274.5 162.3 274.3 163.6 274 166.1C273.467 169.299 273.812 172.583 275 175.6C275.259 176.316 275.46 177.052 275.6 177.8C275.9 178.7 276.2 179.6 276.6 180.5C278.027 183.924 278.774 187.592 278.8 191.3C279 198.8 281.1 202.5 286.2 204C286.6 210.1 286.8 212.4 289.6 212.9Z" fill="#66BCFF"/>
            <path d="M254.1 125.7C251.2 123.2 249 123.4 245.5 124.9C241.8 121.5 237 121 232 122.7C227 124.4 219.3 126.7 214.8 127.9C214.549 127.994 214.276 128.013 214.014 127.956C213.752 127.898 213.512 127.767 213.322 127.578C213.133 127.388 213.002 127.148 212.945 126.886C212.887 126.624 212.907 126.351 213 126.1L216.3 114.1C205.6 122.1 202.1 139.2 205.5 148.5C208.9 157.8 223.7 157.7 228.4 155.1C233.1 152.5 234.7 151.7 240.1 152.7C245.5 153.7 249.7 154.4 256.8 149L261 147.6C262.6 138.5 261.2 131.9 254.1 125.7Z" fill="#3442D9"/>
            <path d="M254.1 125.7C251.2 123.2 249 123.4 245.5 124.9C241.8 121.5 237 121 232 122.7C227 124.4 219.3 126.7 214.8 127.9" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M245.5 124.9C248.487 127.213 250.974 130.107 252.813 133.406C254.652 136.706 255.804 140.344 256.2 144.1" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M275.8 121.9C275.845 121.762 275.86 121.616 275.843 121.471C275.826 121.327 275.778 121.188 275.701 121.065C275.625 120.941 275.523 120.835 275.402 120.755C275.281 120.675 275.144 120.622 275 120.6H274C273.722 120.539 273.431 120.589 273.189 120.738C272.946 120.887 272.771 121.124 272.7 121.4L271.8 124.7C271.754 124.846 271.742 125 271.765 125.151C271.788 125.302 271.845 125.445 271.931 125.571C272.018 125.696 272.133 125.8 272.266 125.875C272.399 125.949 272.548 125.992 272.7 126H273.7C273.963 126.034 274.229 125.971 274.45 125.824C274.67 125.677 274.83 125.456 274.9 125.2L275.8 121.9Z" fill="#29CC7A"/>
            <path d="M259.8 102.7C260.573 101.135 261.714 99.7813 263.124 98.7538C264.535 97.7262 266.174 97.0559 267.9 96.8001C268.1 96.8001 268.2 96.6001 268.1 96.5001C266.8 91.7001 267.4 89.0001 269.1 85.5001C269.117 85.4747 269.128 85.4458 269.132 85.4156C269.136 85.3853 269.133 85.3545 269.124 85.3256C269.114 85.2966 269.098 85.2703 269.076 85.2485C269.055 85.2268 269.029 85.2102 269 85.2001C263.4 82.8001 261.3 78.8001 260.7 75.8001C260.6 75.6001 260.3 75.5001 260.2 75.7001C257.7 80.7001 254.3 80.9001 251.4 80.5001C251.2 80.5001 251.1 80.7001 251.2 80.8001C254.1 86.4001 252 90.4001 249.1 93.2001C249.085 93.2393 249.077 93.2814 249.079 93.3236C249.081 93.3658 249.092 93.407 249.111 93.4448C249.13 93.4826 249.156 93.5159 249.189 93.5427C249.221 93.5694 249.259 93.589 249.3 93.6001C254.9 94.6001 257.9 98.3001 259.3 102.6C259.4 102.8 259.7 102.9 259.8 102.7Z" fill="#FFC619"/>
            <path d="M272.8 138.2L267.1 137.4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M283.2 137.9L286.4 138.4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M260.5 75.6001C263 70.0001 262.8 66.1001 262.1 63.9001" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M268.5 85.4001C270.06 85.6392 271.654 85.5397 273.172 85.1083C274.691 84.677 276.099 83.9239 277.3 82.9001" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M259.8 102.7C259.808 103.444 259.707 104.186 259.5 104.9" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M249.6 93.3002C245.9 95.9002 240.3 95.5002 236.4 94.3002" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M251.4 80.5002C250 77.6002 247.4 74.3002 245 73.2002" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M268.1 96.5001C269.39 97.1886 270.77 97.6935 272.2 98.0001" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M257.4 85.8002C259.1 92.4002 261.3 92.0002 262.3 90.0002C263.3 88.0002 264 85.5002 265.6 85.7002C267.2 85.9002 266.8 88.7002 264.9 92.7002C263 96.7002 259.9 97.6002 257 94.1002C254.1 90.6002 254 87.2002 253.9 84.8002C253.8 82.4002 256.3 81.8002 257.4 85.8002Z" fill="#C40655"/>
            <path d="M253.4 106.6C254.6 120.4 247.6 127 245.4 140.7" stroke="white" strokeWidth="3.00593" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M269.1 183.4C267.3 177.5 266.5 166.7 271.4 157.2" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M268 168.7C266.872 166.508 266.321 164.064 266.4 161.6" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M218.7 126.8L211.3 129.2" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M292.2 125.9L297.2 127.3" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M381.4 192.2C380.851 193.002 380.214 193.74 379.5 194.4C376.3 197.6 369 201.2 365.1 202.7C362.133 203.639 359.404 205.208 357.1 207.3C354.2 209.6 350.6 212.7 348.1 210.9C344.951 208.001 342.073 204.821 339.5 201.4C339.354 201.221 339.259 201.006 339.224 200.777C339.188 200.549 339.215 200.315 339.3 200.1C340.7 196.1 345.2 190.4 351.7 187.4C351.915 187.315 352.149 187.288 352.377 187.324C352.606 187.359 352.821 187.454 353 187.6C356.8 190.3 359.1 191 362.2 190.9C365.3 190.8 371.3 188.8 376.2 187.4C381.1 186 381.2 188.2 381.4 192.2Z" fill="#FFC619"/>
            <path d="M342.3 204.9C344.086 207.038 346.024 209.043 348.1 210.9C350.6 212.7 354.2 209.6 357.1 207.3C359.404 205.208 362.133 203.639 365.1 202.7C369 201.2 376.3 197.6 379.5 194.4C380.214 193.74 380.851 193.002 381.4 192.2C381.2 188.2 380.3 186.2 376.2 187.4C372.1 188.6 369.8 189.6 366.8 190.3C365.908 190.539 365.099 191.021 364.463 191.691C363.828 192.362 363.39 193.196 363.2 194.1C363.1 195.2 362.9 196.3 362.7 197.6C357.3 196.5 352.1 198.3 351 205.4C351 205.4 346.4 202.9 342.3 204.9Z" fill="#FFE75C"/>
            <path d="M379.5 194.4L381.4 192.3C381.527 192.089 381.596 191.847 381.6 191.6C381.508 190.842 381.305 190.101 381 189.4C380.2 190.9 378.8 192.4 375.7 194.7C370.8 198.3 365.3 199.6 362 200.7C358.979 201.826 356.222 203.562 353.9 205.8C352.075 207.862 349.67 209.325 347 210L348.1 210.9C350.6 212.7 354.2 209.6 357.1 207.3C359.404 205.208 362.133 203.639 365.1 202.7C369 201.2 376.3 197.6 379.5 194.4Z" fill="white"/>
            <path d="M318.5 198.9C313.4 197.5 308.2 199.9 303.6 208.6C302.3 207.6 299.1 206.5 294.3 207.4C289.5 208.3 287.7 210.4 286.9 212C286.424 212.924 286.283 213.984 286.5 215C286.8 216.3 287.2 218 287.8 220.8C288.7 225.1 288.2 228.9 290.6 229.3L324.2 205.1C324.2 205.1 325.1 200.7 318.5 198.9Z" fill="#FFC619"/>
            <path d="M318.5 198.9C313.4 197.5 308.2 199.9 303.6 208.6C302.962 209.902 302.394 211.237 301.9 212.6C296.6 212.1 294.1 214.4 293.2 219.5C293.2 219.5 289.7 217.1 287.6 219.9C287.7 220.2 287.7 220.5 287.8 220.8C288.7 225.1 288.2 228.9 290.6 229.3L324.2 205.1C324.2 205.1 325.1 200.7 318.5 198.9Z" fill="#FFE75C"/>
            <path d="M324.2 203.7L321.7 201.4C317.4 200.7 313.8 200.6 310 204.9C306.2 209.2 303.7 213.9 299.7 216.7C294 220.9 289.2 222.6 288.5 225.7C287.8 228.8 289.2 229.1 290.6 229.3L324.2 205.1C324.323 204.642 324.323 204.159 324.2 203.7Z" fill="white"/>
            <path d="M319.9 199.9L322.3 200.6C322.509 200.634 322.707 200.714 322.88 200.835C323.054 200.957 323.197 201.116 323.3 201.3C324.227 202.866 324.613 204.693 324.4 206.5L315.6 204.9C318.5 203.5 319.5 202.2 319.2 200.5C319.17 200.406 319.168 200.306 319.195 200.211C319.222 200.116 319.276 200.032 319.351 199.968C319.426 199.903 319.518 199.863 319.615 199.851C319.713 199.839 319.812 199.856 319.9 199.9Z" fill="white"/>
            <path d="M290.8 229.4C299.4 231.1 303.2 228.4 306.9 224.6C308.435 222.902 310.222 221.452 312.2 220.3C317.3 217.4 321.3 214.3 324 209.4C326.7 204.5 318.4 201.3 313 205.8C307.6 210.3 307.3 215.6 296.6 222.2C289.3 226.8 288.3 228.9 290.8 229.4Z" fill="black"/>
            <path d="M292.023 213.793C292.073 212.801 291.622 211.971 291.015 211.94C290.409 211.909 289.876 212.689 289.826 213.682C289.775 214.675 290.227 215.505 290.833 215.535C291.44 215.566 291.973 214.786 292.023 213.793Z" fill="white"/>
            <path d="M381.7 191C381.524 189.986 381.187 189.007 380.7 188.1C380.6 188 380.5 187.9 380.4 188H380.3C380.1 188 380.1 188.1 380.1 188.2C380.6 190.3 380.8 192.7 379.7 194.1C382 192.3 381.8 191.3 381.7 191Z" fill="white"/>
            <path d="M346.024 201.633C346.941 201.428 347.43 200.126 347.116 198.724C346.803 197.323 345.806 196.353 344.89 196.558C343.974 196.763 343.485 198.065 343.798 199.466C344.111 200.867 345.108 201.837 346.024 201.633Z" fill="white"/>
            <path d="M420.999 351.4C463.299 351.4 497.999 333.8 543.599 293.6C589.199 253.4 605.199 240.2 618.099 227.7C630.999 215.2 630.099 207.1 623.399 191.1C616.699 175.1 612.299 177.4 612.299 177.4C611.499 164.4 598.799 142.9 584.799 125.6C570.799 108.3 562.399 111.1 546.999 111.1C531.599 111.1 526.499 115.3 519.599 133.7C512.699 152.1 495.499 167.7 462.999 173.4C430.499 179.1 412.699 178.8 411.799 195.1C410.899 211.4 411.799 239.5 397.399 261.7C379.498 270.352 360.679 276.96 341.299 281.4C329.899 284 327.499 291.5 326.399 301.3C325.299 311.1 329.299 316.4 331.199 319.5C327.299 323.2 328.099 324.8 329.999 333.5C330.799 337 331.699 340.5 332.499 343.6C333.151 345.853 334.518 347.833 336.394 349.24C338.27 350.647 340.554 351.405 342.899 351.4H420.999Z" fill="#66BCFF"/>
            <path d="M326.399 301.3C325.299 311.1 329.299 316.4 331.199 319.5L409.499 328.5L485.299 317.4C485.299 317.4 535.299 277.1 528.499 263.7C521.699 250.3 507.399 262.9 488.299 275.9C469.199 288.9 449.399 290.1 426.699 286.8C403.999 283.5 376.499 273.8 341.299 281.4C329.899 284 327.499 291.5 326.399 301.3Z" fill="#45AAF7"/>
            <path d="M536.599 257C536.699 239.7 536.099 235.6 541.899 236.4C547.699 237.2 557.199 239.1 557.199 239.1L546.899 255.4L536.599 257Z" fill="#A3E1FF"/>
            <path d="M566.999 230.1C566.999 221.7 566.299 216.2 568.599 215C570.899 213.8 573.599 215.3 578.399 219.6C583.199 223.9 566.999 230.1 566.999 230.1Z" fill="#A3E1FF"/>
            <path d="M336.199 317.4C334.899 307.3 335.199 299.3 337.899 297.3C340.599 295.3 343.199 299.9 345.099 303.9C347.521 308.976 350.543 313.743 354.099 318.1L336.199 317.4Z" fill="#A3E1FF"/>
            <path d="M422.999 319.6C426.299 306.1 429.099 302.8 431.899 302.6C434.699 302.4 436.099 308.8 440.099 317.9L433.499 321L422.999 319.6Z" fill="#A3E1FF"/>
            <path d="M602.999 196.1C560.499 237.2 535.099 259.8 499.499 286.3C463.899 312.8 447.999 319.7 418.099 319.7C388.199 319.7 368.999 302.9 331.199 319.5C327.299 323.2 328.099 324.8 329.999 333.5C330.799 337 331.699 340.5 332.499 343.6C333.151 345.853 334.518 347.833 336.394 349.24C338.27 350.647 340.554 351.405 342.899 351.4H420.999C463.299 351.4 497.999 333.8 543.599 293.6C589.199 253.4 605.199 240.2 618.099 227.7C630.999 215.2 630.099 207.1 623.399 191.1C616.699 175.1 612.299 177.4 612.299 177.4C612.299 181.9 612.599 186.7 602.999 196.1Z" fill="white"/>
            <path d="M623.899 192.2C623.799 198 620.199 204.6 610.399 213.6C591.199 231.1 551.499 266.9 524.199 290.1C494.299 315.8 466.499 337 429.499 337C416.599 337 402.499 334.3 388.799 331.7C364.699 327.1 341.799 322.8 330.199 334.4C330.299 335 330.499 335.7 330.599 336.3C341.399 324.4 364.299 328.7 388.499 333.3C402.299 335.9 416.499 338.6 429.499 338.6C466.999 338.6 496.199 316.2 525.199 291.4C552.499 268.1 592.299 232.2 611.399 214.8C618.599 208.2 623.799 202.1 625.099 195.4L623.899 192.2Z" fill="#FFE75C"/>
            <path d="M511.999 319L498.099 287.4C492.766 291.333 487.866 294.833 483.399 297.9L482.199 299.3C487.399 297.1 492.599 300 492.999 308.9C493.099 313.7 493.099 323.4 493.099 331.2C499.616 327.479 505.925 323.407 511.999 319Z" fill="black"/>
            <path d="M348.699 343.4C340.699 343.5 336.799 346.7 339.999 349.8C341.164 350.882 342.71 351.457 344.299 351.4H412.999C378.799 351.4 375.299 343 348.699 343.4Z" fill="black"/>
            <path d="M584.299 166.9C573.999 180.1 570.399 185.3 574.699 199.4C576.499 205.5 578.799 212 580.699 217.4L602.999 196.1C612.599 186.7 612.299 181.9 612.299 177.4C611.599 166.3 602.299 149 590.799 133.4C596.599 141.8 594.599 153.7 584.299 166.9Z" fill="#45AAF7"/>
            <path d="M570.599 150.1C576.499 127.6 575.899 112.6 561.399 111.1C557.099 110.7 552.499 111.1 546.999 111.1C531.499 111.1 526.499 115.3 519.599 133.7C512.699 152.1 495.499 167.7 462.999 173.4C430.499 179.1 412.699 178.8 411.799 195.1C410.899 211.4 411.799 239.5 397.399 261.7C395.299 265.4 397.799 267.9 427.699 267.9C457.599 267.9 475.399 266.1 480.399 253.6C485.399 241.1 492.599 213.8 496.199 203C499.799 192.2 499.199 186 519.099 181.5C538.999 177 564.999 171.8 570.599 150.1Z" fill="#8CD9FF"/>
            <path d="M501.499 178.7C517.499 172.1 528.799 173.6 545.799 165.5C562.799 157.4 564.999 151.4 567.099 140.3C569.199 129.2 570.699 116.1 560.399 115.2C550.099 114.3 540.799 114.7 536.099 116.3C531.399 117.9 530.199 120.8 525.099 131.3C519.999 141.8 517.499 157.4 494.599 167.6L501.499 178.7Z" fill="black"/>
            <path d="M366.699 312.4C366.799 317.5 368.199 324.8 370.999 325.5C373.799 326.2 375.099 320.5 377.999 313.4C374.899 310.2 366.699 306.9 366.699 312.4Z" fill="#A3E1FF"/>
            <path d="M494.299 289.2C491.821 289.302 489.397 289.951 487.199 291.1C477.899 295.8 477.999 299.8 478.299 301.2C490.999 293.5 496.599 302 500.199 326.9C501.599 336.7 504.099 341.5 511.299 341.5C520.099 341.5 525.299 335.3 524.699 319.3C524.564 316.281 524.23 313.275 523.699 310.3C521.199 296.9 515.999 283.4 503.399 283.4C501.509 283.494 499.677 284.078 498.081 285.095C496.485 286.112 495.182 287.527 494.299 289.2Z" fill="#FF78B9"/>
            <path d="M588.199 244.1C582.799 248.9 577.399 249.3 576.199 245.1C574.999 240.9 578.299 233.5 583.699 228.8L593.099 220.5C598.499 215.7 603.899 215.3 605.099 219.5C606.299 223.7 602.899 231.1 597.599 235.8L588.199 244.1Z" fill="#FFA8D2"/>
            <path d="M494.299 289.2C502.699 290.1 509.099 294 511.799 304.6C513.699 312.1 513.099 320.9 514.399 328.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M589.999 169.2L587.499 172.6C579.599 182.9 576.699 186.7 585.099 213.2L586.399 212C578.499 187 581.099 183.5 588.699 173.6L591.299 170.2C597.499 161.8 599.799 153.5 599.599 146.4L597.799 143.6C598.799 150.9 596.799 160 589.999 169.2Z" fill="#5865F2"/>
            <path d="M476.099 307.6C476.899 303.1 475.899 300.9 473.599 301.2C471.299 301.5 469.299 302.9 467.099 308C468.899 327.5 474.099 319.9 476.099 307.6Z" fill="#A3E1FF"/>
            <path d="M558.301 118.4L574.001 116.4C576.501 116.1 577.401 110.4 578.501 103.2C579.601 96.0001 578.301 82.9001 578.301 82.9001L562.101 87.8001L558.301 118.4Z" fill="#FF78B9"/>
            <path d="M601.901 78.2001C595.201 70.7001 561.201 44.5001 561.201 44.5001L553.201 56.0001C553.201 56.0001 564.501 68.5001 572.601 76.9001C556.201 78.2001 526.601 82.1001 526.601 82.1001C505.601 77.9001 496.701 75.9001 496.701 75.9001L518.301 55.4001L510.401 43.6001C510.401 43.6001 489.801 56.2001 478.201 65.3001C466.601 74.4001 464.701 85.6001 475.701 94.1001C486.701 102.6 490.401 105.2 490.401 105.2L561.301 112.5C582.101 106.7 594.501 100.4 602.001 93.9001C609.501 87.4001 608.601 85.7001 601.901 78.2001Z" fill="#FFC619"/>
            <path d="M484.501 74.8C487.001 75.2 491.401 76.4001 491.401 76.4001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M556.901 40.9001L549.801 52.8001L557.801 61.0002C560.401 58.1002 563.001 53.0001 562.201 45.3001L556.901 40.9001Z" fill="black"/>
            <path d="M510.901 92.9001C501.301 81.1001 497.901 76.0001 495.901 70.1001C494.501 65.8001 503.001 68.4001 514.001 71.7001C525.001 75.0001 523.301 78.1001 510.901 92.9001Z" fill="#FF78B9"/>
            <path d="M497.101 75.6001C487.001 86.9001 486.801 100.7 487.901 120C487.901 120 470.501 137.5 473.001 152.8C475.501 168.1 499.301 174.7 526.401 174.4C548.201 174.2 550.601 169.1 552.601 158.8C553.801 152.1 554.701 144.4 555.301 138.4C555.906 132.093 557.492 125.918 560.001 120.1C573.401 89.0001 562.601 77.8001 562.601 77.8001L526.401 80.7001L497.101 75.6001Z" fill="#FF78B9"/>
            <path d="M505.101 59.4001C496.601 65.7001 498.401 75.3001 510.401 88.5001L515.801 93.9001L550.701 88.2001C561.901 74.3001 559.701 65.0001 555.601 59.2001C561.301 49.8001 561.701 43.3001 561.601 37.5001C561.501 27.7001 552.601 12.3001 530.201 12.5001C518.001 12.6001 507.201 17.4001 503.301 32.9001C501.901 38.3001 501.601 47.1001 505.101 59.4001Z" fill="#FFE75C"/>
            <path d="M548.301 25.2001C548.568 25.1986 548.833 25.2536 549.077 25.3613C549.322 25.469 549.541 25.6272 549.72 25.8253C549.9 26.0235 550.035 26.2573 550.118 26.5115C550.201 26.7656 550.229 27.0343 550.201 27.3001L549.001 40.8001C548.646 43.8279 547.222 46.6296 544.986 48.7011C542.749 50.7726 539.847 51.9779 536.801 52.1001H534.101C530.917 52.1303 527.831 50.9999 525.42 48.9202C523.009 46.8405 521.438 43.9539 521.001 40.8001L520.501 36.3001C520.428 35.77 520.164 35.2847 519.759 34.935C519.354 34.5853 518.836 34.3951 518.301 34.4001C517.75 34.3978 517.219 34.602 516.812 34.9722C516.405 35.3425 516.151 35.852 516.101 36.4001L515.501 43.6001C515.279 45.9795 514.187 48.1935 512.434 49.8175C510.681 51.4415 508.39 52.3611 506.001 52.4001C504.568 52.4542 503.141 52.1966 501.818 51.6451C500.495 51.0936 499.307 50.2613 498.337 49.2057C497.367 48.1502 496.638 46.8966 496.2 45.5317C495.762 44.1667 495.626 42.7229 495.801 41.3001L497.001 27.7001C497.072 27.1603 497.331 26.6631 497.734 26.2968C498.137 25.9306 498.657 25.7192 499.201 25.7001L548.301 25.2001Z" fill="black"/>
            <path d="M549.601 25.7001L561.601 37.5001V39.9001L549.701 31.6001L549.601 25.7001Z" fill="black"/>
            <path d="M562.601 77.7C556.972 72.383 550.344 68.2362 543.101 65.5C536.601 63.2 535.101 62.6 533.601 66.3C532.101 70 530.501 74.2 529.901 75.6C529.301 77 522.201 81.8 517.901 85.4C513.601 89 509.101 96.7 509.101 96.7L554.701 100.2L562.601 77.7Z" fill="#FF78B9"/>
            <path d="M572.401 76.7001L578.301 82.9001C575.601 91.3001 573.901 101.8 572.901 113.5L549.601 115.2L562.601 77.8001L572.401 76.7001Z" fill="#FF78B9"/>
            <path d="M497.101 75.6L492.801 74.8C492.141 74.7044 491.469 74.7532 490.83 74.9432C490.191 75.1331 489.601 75.4596 489.101 75.9001C484.206 80.3554 480.904 86.2912 479.701 92.8C478.401 100.4 478.801 101.2 487.601 105.9C493.501 109.1 499.601 95.1 502.901 85.6C503.284 84.5643 503.434 83.4567 503.339 82.3564C503.245 81.2561 502.909 80.1902 502.355 79.2349C501.8 78.2796 501.042 77.4585 500.134 76.8301C499.226 76.2018 498.19 75.7818 497.101 75.6Z" fill="#FF78B9"/>
            <path d="M501.301 153.4C493.645 144.477 485.026 136.428 475.601 129.4L453.201 141.1L460.501 162.9C460.501 162.9 466.501 170.5 472.101 176.5C477.701 182.5 487.801 182.7 506.401 184.3C513.401 171.8 510.201 163.9 501.301 153.4Z" fill="#2835BD"/>
            <path d="M464.801 162.5L466.801 152.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M543.401 149.3C541.601 144.4 536.101 140.5 511.501 139.3L502.401 130.1L477.101 149.4C486.101 164.6 496.301 182.9 506.401 184.3C516.501 185.7 525.601 185 537.601 177.7C545.201 173 546.201 170.8 546.401 166.8C546.248 160.853 545.238 154.959 543.401 149.3Z" fill="#3442D9"/>
            <path d="M570.601 150.1C565.001 171.8 539.001 176.9 519.101 181.5C501.101 185.6 499.901 191 497.201 200L483.601 196.4V182.4L501.501 178.7C517.501 172.1 528.801 173.6 545.801 165.5C562.801 157.4 565.001 151.4 567.101 140.3C568.401 133.8 569.401 126.4 567.801 121.3L573.501 122.1C575.001 128.9 573.701 138.5 570.601 150.1Z" fill="#8CD9FF"/>
            <path d="M418.401 171.6C417.075 172.403 416.058 173.629 415.514 175.08C414.969 176.531 414.93 178.123 415.401 179.6C418.301 188.8 419.601 195.9 416.901 218.3C414.201 240.7 409.701 253.1 407.601 257.7C407.472 257.973 407.41 258.273 407.419 258.575C407.429 258.876 407.511 259.171 407.657 259.435C407.804 259.699 408.011 259.924 408.262 260.092C408.513 260.26 408.801 260.366 409.101 260.4C420.301 262.1 437.101 261.9 455.501 259.5C458.605 259.093 461.546 257.869 464.023 255.954C466.5 254.039 468.425 251.502 469.601 248.6C474.331 236.862 478.208 224.797 481.201 212.5C487.901 184.9 487.101 185.4 501.501 178.7C500.401 168.8 500.501 159.3 489.701 157.9C477.101 156.3 463.301 162.5 443.501 167.9C439.001 163.9 434.901 161.9 418.401 171.6Z" fill="#45AAF7"/>
            <path d="M472.601 159.4C464.301 161.4 454.901 164.8 443.501 167.9C440.201 165 437.101 163.1 429.201 166.2C428.908 166.948 428.674 167.717 428.501 168.5C424.901 186.4 418.101 215.7 438.501 214.1C458.901 212.5 461.201 200.4 460.601 189.4C460.001 178.4 458.701 172.3 460.601 169.1C462.501 165.9 469.201 160.3 472.601 159.4Z" fill="#287CEB"/>
            <path d="M490.901 184.7C489.501 173 488.601 167.4 485.401 165.2C482.201 163 481.101 163.7 476.901 164.6C472.701 165.5 467.401 167.1 460.601 169.1C447.901 172.8 432.101 177.5 426.101 177.1L424.101 176.8C425.501 175.3 434.101 172.2 439.801 170.1L443.801 168.6C443.895 168.577 443.983 168.535 444.06 168.477C444.137 168.418 444.201 168.344 444.248 168.26C444.295 168.175 444.324 168.082 444.333 167.985C444.342 167.889 444.331 167.792 444.301 167.7L443.501 167.9L442.801 167.3L439.201 168.6C425.901 173.5 422.101 175.2 422.401 177.1C422.701 179 424.201 178.6 426.001 178.7C432.201 179.1 447.501 174.6 461.101 170.6C467.801 168.6 473.601 166.9 477.201 166.2C480.801 165.5 483.101 165.5 484.501 166.5C485.901 167.5 488.101 174.5 489.401 186.3L490.901 184.7Z" fill="black"/>
            <path d="M496.001 39.3001L515.001 25.5001L507.901 25.6001L496.501 33.9001L496.001 39.3001Z" fill="#5865F2"/>
            <path d="M500.601 51.0001C502.238 51.9546 504.106 52.4391 506.001 52.4001C508.39 52.3611 510.681 51.4415 512.434 49.8175C514.187 48.1935 515.279 45.9795 515.501 43.6001L515.801 40.0001L500.601 51.0001Z" fill="#5865F2"/>
            <path d="M548.301 25.2001H540.401L520.901 39.3001V40.8001C521.126 42.3465 521.634 43.8382 522.401 45.2001L549.501 25.7001C549.164 25.4107 548.744 25.2356 548.301 25.2001Z" fill="#5865F2"/>
            <path d="M549.001 40.8L549.601 34.3L527.401 50.4001C529.451 51.5343 531.758 52.1199 534.101 52.1H536.801C539.847 51.9779 542.749 50.7726 544.986 48.701C547.222 46.6295 548.646 43.8278 549.001 40.8Z" fill="#5865F2"/>
            <path d="M514.501 53.6001C517.169 53.0179 519.932 53.0179 522.601 53.6001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M430.701 133C430.801 133.3 430.901 133.5 431.001 133.5C431.101 133.5 431.201 133.4 431.301 133.1" fill="black"/>
            <path d="M447.701 128.4C436.901 132.6 421.801 141.4 408.801 148.7C401.601 152.7 399.401 155.1 403.201 162.4C407.001 169.7 414.201 176.7 419.601 181.5C425.001 186.3 428.001 184.3 432.001 181.7L460.501 162.9L475.601 129.4C469.301 125 458.601 124.1 447.701 128.4Z" fill="#2835BD"/>
            <path d="M417.201 168.1C421.801 169 421.801 169.5 427.501 165.8C433.201 162.1 461.901 144.8 464.801 142.9" stroke="#4150F2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M489.401 117.8C484.901 114.5 481.801 114.3 457.601 112.3C433.401 110.3 422.101 107.8 419.301 112.2C416.501 116.6 419.001 127.6 421.801 135.9C424.601 144.2 426.201 146.9 430.101 147.2C434.001 147.5 470.401 148.7 477.101 149.4L480.401 155L511.501 139.3C511.501 139.3 493.801 121.1 489.401 117.8Z" fill="#3442D9"/>
            <path d="M412.601 161.4C406.601 156.5 404.201 155.6 404.901 158.9C405.601 162.2 409.401 167.1 413.501 171.5C417.601 175.9 422.301 180.4 423.901 180.1C425.501 179.8 423.601 176.8 421.101 172C418.966 167.959 416.081 164.362 412.601 161.4Z" fill="black"/>
            <path d="M419.201 168.7L414.201 172.2L410.101 175.1C405.201 178.7 407.201 181.6 407.601 185C408.001 188.4 406.901 190.9 404.601 190.9C402.301 190.9 398.901 181.3 396.501 176.7C394.101 172.1 386.201 168.4 382.101 164.9C380.401 163.4 380.001 160.9 382.101 160.1C381.201 158.8 380.001 155.5 383.001 154.5C386.001 153.5 390.501 159 393.001 160.7C395.501 162.4 398.601 164 400.901 163C402.482 162.296 404.018 161.494 405.501 160.6L408.901 158.5L412.601 161.4C415.112 163.533 417.331 165.987 419.201 168.7Z" fill="#FF78B9"/>
            <path d="M570.601 150.1C565.001 171.8 539.001 176.9 519.101 181.5C499.201 186.1 499.801 192.1 496.201 203C492.601 213.9 485.401 241.2 480.401 253.6L546.901 214.5L574.101 162.9L570.601 150.1Z" fill="#66BCFF"/>
            <path d="M493.501 237.9C491.301 245.6 489.501 251.4 488.501 255.6C487.501 259.8 492.101 259.4 497.001 258C501.901 256.6 504.001 254.5 503.201 250.2C502.401 245.9 495.901 229.2 493.501 237.9Z" fill="white"/>
            <path d="M412.601 161.4L408.901 158.5C408.001 158.4 407.501 158.4 407.101 158.7C406.701 159 406.801 159.2 406.901 159.7C409.201 159.3 414.901 163.4 415.101 171.5C416.401 172.1 417.101 172.5 417.601 172.2C418.101 171.9 418.801 170.5 419.201 168.7C417.331 165.987 415.112 163.533 412.601 161.4Z" fill="black"/>
            <path d="M403.801 186.5C402.801 182.7 399.401 174.9 396.501 171.8C393.601 168.7 389.001 161.4 387.501 159C386.001 156.6 382.101 153.6 381.201 156.3C381.006 157.635 381.328 158.994 382.101 160.1C380.001 160.9 380.401 163.4 382.101 164.9C386.201 168.4 393.901 171.6 396.501 176.7C399.101 181.8 400.901 189.2 403.701 190.7L404.501 190.9C405.001 190.8 404.701 189.4 403.801 186.5Z" fill="#DE3A93"/>
            <path d="M518.201 6.5001C516.934 10.0718 516.794 13.9465 517.801 17.6001C517.96 18.2428 518.335 18.8115 518.863 19.2118C519.39 19.612 520.039 19.8196 520.701 19.8001H526.801C527.48 19.8032 528.152 19.6623 528.773 19.3865C529.393 19.1106 529.948 18.7063 530.401 18.2001C534.101 14.3001 540.801 13.3001 546.001 15.5001L549.101 11.0001C549.433 10.5593 549.596 10.0137 549.559 9.4629C549.522 8.91213 549.289 8.39293 548.901 8.0001C545.869 5.13476 542.251 2.9622 538.297 1.63271C534.343 0.303222 530.148 -0.151461 526.001 0.300101C524.254 0.509967 522.6 1.20074 521.223 2.29545C519.846 3.39015 518.799 4.84577 518.201 6.5001Z" fill="#FF8C19"/>
            <path d="M573.301 108.8C572.901 113.5 573.701 115.1 574.801 113.2C575.574 111.494 576.048 109.667 576.201 107.8L573.301 108.8Z" fill="black"/>
            <path d="M561.301 78.1001C552.701 79.2001 547.901 80.7001 543.601 82.7001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M578.901 84.1001L582.601 87.8001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M557.001 119.6C557.001 119.6 552.501 122.9 549.601 125.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M517.501 93.8L516.701 105.6H511.401C511.711 101.727 512.313 97.8828 513.201 94.1C513.399 93.4543 513.703 92.8461 514.101 92.3V92.3C515.431 90.6348 516.978 89.1551 518.701 87.9001L527.901 81.0001C530.505 79.0803 532.487 76.4374 533.601 73.4001C534.901 70.0001 536.501 65.7 537.201 64.8" stroke="#F551A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M573.201 77.5001C569.001 88.1001 564.401 106.1 567.901 114.7C568.252 115.51 568.688 116.281 569.201 117L571.201 116.8C570.444 116.015 569.834 115.101 569.401 114.1C566.201 106.4 570.201 89.8001 574.401 78.8001L573.201 77.5001Z" fill="#F551A0"/>
            <path d="M480.601 101.4C481.001 101.872 481.473 102.277 482.001 102.6C483.001 90.9001 487.801 81.0001 495.201 75.2001L493.201 74.9001C486.301 80.8001 481.701 90.3001 480.601 101.4Z" fill="#F551A0"/>
            <path d="M560.901 118.9L549.801 120.2" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M546.001 161.3C542.001 164 533.501 168.1 528.101 169.3C526.752 169.577 525.378 169.711 524.001 169.7C521.835 169.781 519.683 169.331 517.731 168.39C515.779 167.448 514.087 166.045 512.801 164.3L507.601 157C503.101 150.6 497.401 142.7 493.301 137C493.201 136.8 493.201 136.7 493.101 136.6C486.901 127.9 483.601 124.1 479.001 123.4C474.401 122.7 461.101 121.5 437.801 119.7C432.001 119.3 431.101 119.4 430.001 122C429.969 122.098 429.916 122.188 429.846 122.263C429.776 122.338 429.69 122.397 429.595 122.435C429.499 122.473 429.397 122.49 429.294 122.484C429.191 122.478 429.091 122.449 429.001 122.4C428.817 122.314 428.668 122.169 428.577 121.987C428.486 121.806 428.459 121.599 428.501 121.4C430.101 117.5 432.501 117.7 437.901 118.1C451.501 119.2 474.801 121 479.301 121.8C483.801 122.6 488.701 127.7 494.401 135.7C494.401 135.8 494.501 135.9 494.601 136C498.701 141.7 504.301 149.7 508.901 156L514.101 163.4C516.901 167.3 522.001 168.9 527.701 167.7C533.401 166.5 542.701 161.7 545.801 159.5C545.917 160.094 545.984 160.696 546.001 161.3Z" fill="#5865F2"/>
            <path d="M422.401 113.9C419.501 116.6 421.001 121.9 423.001 129.2C425.001 136.5 427.501 143.1 428.801 142.9C430.101 142.7 430.801 140.1 431.101 136.3C431.401 132.5 431.301 125.7 429.801 120.6C428.301 115.5 424.601 111.8 422.401 113.9Z" fill="black"/>
            <path d="M431.301 133.1L423.901 132.2C420.301 131.7 416.701 131.4 413.101 131.2C412.401 135.5 410.901 141 408.801 143.2C406.701 145.4 403.001 145.3 402.601 140.3C402.201 135.3 402.501 128.2 399.201 113.4C398.001 107.9 395.801 104.6 395.001 100.4C394.201 96.2001 396.601 95.5001 398.801 95.9001C398.301 93.1001 398.501 89.5001 402.001 89.0001C405.501 88.5001 406.901 91.5001 406.901 95.4001C407.42 94.6815 408.168 94.1612 409.023 93.9249C409.877 93.6886 410.786 93.7504 411.601 94.1001C414.001 95.0001 414.501 98.0001 414.201 102.8C413.901 107.6 413.801 112.7 415.801 114.4C417.801 116.1 418.301 115.5 421.101 115.9L427.901 116.5C428.712 117.777 429.351 119.156 429.801 120.6C430.835 124.685 431.339 128.886 431.301 133.1Z" fill="#FF78B9"/>
            <path d="M431.301 133.1C431.339 128.886 430.835 124.685 429.801 120.6C429.351 119.156 428.712 117.777 427.901 116.5H427.001C429.501 119.7 431.001 128.1 427.001 132.6C427.564 133.077 428.207 133.449 428.901 133.7C429.746 133.781 430.593 133.57 431.301 133.1Z" fill="black"/>
            <path d="M411.701 100C412.101 95.3001 408.801 93.3001 406.901 95.4001C406.901 91.5001 405.601 88.5001 402.001 89.0001C398.401 89.5001 398.301 93.1001 398.801 95.9001C396.601 95.5001 394.201 96.3001 395.001 100.4C395.801 104.5 398.001 107.9 399.201 113.4C402.501 128.2 402.101 134.4 402.601 140.3C402.608 141.489 402.993 142.645 403.701 143.6C406.001 146 407.001 143.1 406.901 138.7C406.846 131.814 407.247 124.933 408.101 118.1C409.101 110.1 411.401 104.7 411.701 100Z" fill="#DE3A93"/>
            <path d="M402.401 89.0001C405.501 88.9001 405.601 92.8001 405.501 97.1001L406.901 95.4001C406.901 91.7001 405.701 88.7001 402.401 89.0001Z" fill="#FF78B9"/>
            <path d="M482.701 138.2C481.129 142.074 479.257 145.819 477.101 149.4C481.701 147.4 483.601 146.4 483.601 146.4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M512.401 140.2L523.001 151C531.601 152.1 533.601 153.9 535.901 156.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M523.001 151L532.701 151.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M496.601 252.1C495.301 255.4 493.101 257.6 491.701 257.1C490.301 256.6 490.301 255 491.701 251.9C492.304 250.642 492.806 249.338 493.201 248C494.001 245.1 495.501 244.6 496.601 245.1C497.701 245.6 498.001 248.7 496.601 252.1Z" fill="black"/>
            <path d="M418.001 207.6C417.901 209.3 417.701 211.2 417.501 213.2L439.901 215.7L416.301 223.1C415.801 226.5 415.301 229.6 414.801 232.5L430.401 234.1C425.101 236 419.301 238 413.101 240.4C412.301 243.933 411.501 246.933 410.701 249.4L418.001 249.6L409.801 251.9C409.2 253.881 408.465 255.819 407.601 257.7C407.499 257.957 407.432 258.226 407.401 258.5C416.301 255.7 425.301 253.4 434.001 251.5C442.883 253.103 451.639 255.342 460.201 258.2C462.365 257.29 464.309 255.926 465.901 254.2C462.501 253 457.501 251.4 451.401 249.7L447.901 248.8C456.201 247.4 463.901 246.2 470.901 245.3C472.001 242.5 473.201 239.2 474.401 235.6C467.901 234.1 461.201 232.9 454.701 231.8C464.101 228.9 471.501 226.8 477.701 225.3C478.701 221.9 479.701 218.3 480.701 214.5L467.201 213.1L482.101 208.5L483.701 202.2L453.201 211.5L418.001 207.6ZM468.301 240C458.101 241.4 446.201 243.2 433.801 245.9C429.134 245.1 424.234 244.5 419.101 244.1C428.001 240.8 435.801 238 442.801 235.7C450.901 236.8 459.701 238.2 468.301 240ZM476.901 219.7C468.401 221.8 457.401 224.9 442.301 230C434.201 228.9 426.901 228.1 421.001 227.5L453.801 217.2L476.901 219.7Z" fill="#3F4EEB"/>
            <path d="M418.401 199.7C418.301 201.6 418.301 203.7 418.101 206.1C443.701 205.3 473.001 202.2 484.001 200.9C484.601 198.3 485.201 196.1 485.801 194.2C477.901 195.2 446.001 198.9 418.401 199.7Z" fill="#3F4EEB"/>
            <path d="M461.701 252.8L465.901 254.2C464.309 255.926 462.365 257.29 460.201 258.2L456.201 256.9C458.301 255.934 460.175 254.537 461.701 252.8Z" fill="black"/>
            <path d="M471.101 234.9L474.401 235.6C473.201 239.2 472.001 242.5 470.901 245.3L466.501 245.9C468.368 242.382 469.908 238.7 471.101 234.9Z" fill="black"/>
            <path d="M476.801 214.1L480.701 214.5C479.701 218.3 478.701 221.9 477.701 225.3L473.701 226.3C474.301 224.3 474.801 222.2 475.301 220.1C475.401 219.9 475.401 219.7 475.501 219.5C475.901 217.7 476.401 215.9 476.801 214.1Z" fill="black"/>
            <path d="M479.401 203.5L483.701 202.2L482.101 208.5L477.801 209.8C478.301 207.7 478.901 205.6 479.401 203.5Z" fill="black"/>
            <path d="M485.801 194.2C485.201 196.1 484.601 198.3 484.001 200.9L479.901 201.3L481.701 194.7L485.801 194.2Z" fill="black"/>
            <path d="M409.801 251.9L414.801 250.5C414.101 252.7 413.401 254.8 412.601 256.9L407.401 258.5C407.432 258.226 407.499 257.957 407.601 257.7C408.465 255.819 409.2 253.881 409.801 251.9Z" fill="black"/>
            <path d="M413.101 240.4L417.601 238.7C416.901 242.5 416.101 246 415.101 249.5H410.701C411.501 246.967 412.301 243.933 413.101 240.4Z" fill="black"/>
            <path d="M416.301 223.1L420.001 221.9C419.601 225.8 419.101 229.4 418.601 232.9L414.801 232.5C415.301 229.6 415.801 226.5 416.301 223.1Z" fill="black"/>
            <path d="M418.001 207.6L421.401 208L420.801 213.5L417.501 213.2C417.701 211.2 417.901 209.3 418.001 207.6Z" fill="black"/>
            <path d="M422.201 199.6L421.601 206H418.101C418.301 203.6 418.301 201.5 418.401 199.6H422.201Z" fill="black"/>
            <path d="M519.601 225L519.801 233.5C519.807 233.941 519.93 234.372 520.156 234.75C520.383 235.128 520.706 235.44 521.091 235.653C521.477 235.866 521.913 235.974 522.353 235.964C522.794 235.955 523.224 235.829 523.601 235.6C524.052 235.305 524.4 234.877 524.597 234.375C524.794 233.874 524.83 233.323 524.701 232.8L521.801 222.8L519.601 225Z" fill="white"/>
            <path d="M505.001 199.4C513.001 201.9 514.601 210.4 516.301 224.8C516.335 225.154 516.464 225.493 516.673 225.781C516.882 226.07 517.164 226.297 517.49 226.439C517.816 226.582 518.174 226.635 518.528 226.593C518.881 226.551 519.217 226.415 519.501 226.2L522.301 224.1C523.034 223.576 523.609 222.86 523.962 222.031C524.315 221.202 524.432 220.292 524.301 219.4C524.001 217.6 523.501 214.8 523.001 211C521.801 203.6 516.901 194.6 507.501 193.5C506.991 193.449 506.478 193.556 506.03 193.806C505.583 194.057 505.224 194.439 505.001 194.9C504.523 196.039 504.285 197.265 504.301 198.5C504.312 198.704 504.385 198.899 504.51 199.06C504.635 199.22 504.806 199.339 505.001 199.4Z" fill="#3F4EEB"/>
        </svg>

     );
}

export default LandingGraphicLeft;
